import React, { useState } from "react";
import { Col, Row, Form, FormInput, Button } from "shards-react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { createDocumentAction } from "../../../store/Actions/DocumentsActions";

function NewDocCategoryModal({ open, toggle, handleClose }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const compInfo = JSON.parse(sessionStorage.getItem("otheruserProfile"));

  const handleCreateDoc = async val => {
    setLoading(true);
    const response = await dispatch(createDocumentAction(val));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    // <Modal open={open} toggle={toggle} className="modal-overflow">
    <Modal show={open} onHide={handleClose}>
      <Modal.Header> Create Document </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                companyID: ``,
                name: "",
                category: ""
              }}
              validationSchema={yup.object({
                name: yup.string().required("Document name is required"),
                category: yup.string().required("Category is required")
              })}
              onSubmit={values => {
                handleCreateDoc(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="name">Document name</label>
                      <FormInput
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={values.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.name && errors.name}{" "}
                      </small>
                    </Col>
                  </Row>{" "}
                  <Row>
                    <Col lg="12">
                      <label htmlFor="category">Document Category</label>
                      <FormInput
                        id="category"
                        type="text"
                        placeholder="Category"
                        value={values.category}
                        onChange={handleChange("category")}
                        onBlur={handleBlur("category")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.category && errors.category}{" "}
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        className="mx-2 my-2"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        Create Document
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default NewDocCategoryModal;
