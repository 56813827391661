import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import bsCustomFileInput from "bs-custom-file-input";
import { useDispatch } from "react-redux";
import { uploadFileToDocAction } from "../../../store/Actions/DocumentsActions";

const UploadFileToDoc = ({ data, display, refID }) => {
  const [imageFile, setImageFile] = useState(null);
  const [otherFileType, setOtherFileType] = useState(null);
  const [base64Val, setBase64Val] = useState("");
  const [imgDisplay, setImageDisplay] = useState("none");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const reader = new FileReader();
    if (imageFile) {
      // to display image
      reader.readAsDataURL(imageFile);
      reader.onloadend = () => {
        setBase64Val(reader.result);
        setImageDisplay("block");
      };
    }

    if (otherFileType) {
      // to display other file types
      reader.readAsDataURL(otherFileType);
      reader.onloadend = () => {
        setBase64Val(reader.result);
        setImageDisplay("block");
      };
    }
  }, [imageFile, otherFileType]);

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  const handleSubmitDocument = async values => {
    setLoading(true);
    const { fileName } = values;
    const reqObj = {
      data: {
        ...values,
        files: [{ ...values.files[0], fileName, base64: base64Val }]
      }
    };

    const response = await dispatch(uploadFileToDocAction(reqObj));
    if (response) setLoading(false);
  };
  return (
    <>
      <Row>
        <Col>
          <Formik
            initialValues={{
              companyID: `${data && data.companyID}`,
              refID: `${refID && refID}`,
              files: [
                {
                  note: "",
                  isImage: "",
                  fileName: "",
                  docName: "",
                  base64: `${base64Val}`
                }
              ]
            }}
            validationSchema={Yup.object({
              files: Yup.array().of(
                Yup.object().shape({
                  note: Yup.string().required("Description note is required"),
                  isImage: Yup.string().required("Please select file type"),
                  fileName: Yup.string(),
                  docName: Yup.string(),
                  base64: Yup.string()
                })
              )
            })}
            onSubmit={values => {
              // console.log("form submit", values);
              handleSubmitDocument(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              setFieldValue
            }) => (
              <Form>
                <Row>
                  <Col lg="12" className="mt-3">
                    <label htmlFor="file">File Upload</label>
                    <FieldArray name="files">
                      {({ push, remove }) => (
                        <>
                          {values.files.map((item, index) => {
                            const noteName = `files[${index}].note`;
                            const isImageName = `files[${index}].isImage`;
                            const docNameName = `files[${index}].docName`;
                            const fileNameName = `files[${index}].fileName`;
                            const base64Name = `files[${index}].base64`;

                            const noteError = getIn(errors, noteName);
                            const isImageError = getIn(errors, isImageName);
                            const docNameError = getIn(errors, docNameName);
                            const base64Error = getIn(errors, base64Name);
                            const fileNameError = getIn(errors, fileNameName);

                            return (
                              <div key={index}>
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      paddingTop: 5
                                    }}
                                  >
                                    <label>Document {index + 1}</label>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"1rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>

                                  <FormInput
                                    id="files"
                                    name={docNameName}
                                    placeholder="Name of File"
                                    value={item.docName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="mb-2"
                                  />
                                  {docNameError && (
                                    <small className="text-danger">
                                      {docNameError}
                                    </small>
                                  )}

                                  <FormSelect
                                    id="files"
                                    name={isImageName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={item.isImage}
                                    className="my-2"
                                  >
                                    <option value="">Select file type</option>
                                    <option value="1">Image</option>
                                    <option value="0">others</option>
                                  </FormSelect>
                                  {isImageError && (
                                    <small className="text-danger">
                                      {isImageError}
                                    </small>
                                  )}

                                  <FormTextarea
                                    id="files"
                                    name={noteName}
                                    placeholder="Description"
                                    value={item.note}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="my-2"
                                    rows="3"
                                  />

                                  {noteError && (
                                    <small className="text-danger">
                                      {noteError}
                                    </small>
                                  )}

                                  <Col className="custom-file">
                                    <input
                                      id="imgfile"
                                      type="file"
                                      className="custom-file-input"
                                      name={fileNameName}
                                      value={item.fileName}
                                      onChange={e => {
                                        console.log("event ");
                                        const file = e.target.files[0];
                                        if (
                                          file &&
                                          file.type.substr(0, 5) === "image"
                                        ) {
                                          setFieldValue("fileName", file.name);

                                          setImageFile(file);
                                        } else {
                                          setFieldValue("fileName", file.name);
                                          setOtherFileType(file);
                                        }
                                      }}
                                      onBlur={handleBlur}
                                    />
                                    <label
                                      className="custom-file-label"
                                      htmlFor="imgfile"
                                    >
                                      Choose file
                                    </label>
                                    {fileNameError && (
                                      <small className="text-danger">
                                        {fileNameError}
                                      </small>
                                    )}
                                  </Col>
                                  <img
                                    src={base64Val}
                                    width={50}
                                    height={100}
                                    style={{ display: `${imgDisplay}` }}
                                  />
                                </>
                              </div>
                            );
                          })}

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Add More</Tooltip>}
                          >
                            <FiPlusCircle
                              onClick={() => {
                                console.log("reached");
                                return push({
                                  note: "",
                                  isImage: "",
                                  fileName: "",
                                  docName: "",
                                  base64: ""
                                });
                              }}
                              className="my-2"
                              size={24}
                              style={{ cursor: "pointer" }}
                            />
                          </OverlayTrigger>
                        </>
                      )}
                    </FieldArray>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="text-right mt-2">
                    <Button
                      className="my-1"
                      theme="accent"
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      {loading && <Spinner size="sm" variant="secondary" />}
                      Upload
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default UploadFileToDoc;
