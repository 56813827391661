import axios from "./index";

export const _createCompany = reqObj => {
  return axios.post("/v1/admin/create/company", reqObj);
};

export const _getAllCompanies = () => {
  return axios.get("/v1/admin/get/company/-");
};

export const _getCompany = companyID => {
  return axios.get(`/v1/admin/get/company/${companyID}`);
};

export const _updateBasicCompanyInfo = reqObj => {
  return axios.put("/v1/admin/add/company-info/basic", reqObj);
};

export const _updateCompanyEmployee = reqObj => {
  return axios.put("/v1/admin/add/employee", reqObj);
};

export const _updateCompanyMedicalPartner = reqObj => {
  return axios.put("/v1/admin/add/medical-partner", reqObj);
};

export const _updateCompanyConsultant = reqObj => {
  return axios.put("/v1/admin/add/consultant", reqObj);
};
