import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, FormInput, Button } from "shards-react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  deleteDocAction,
  updateDocumentAction
} from "../../../store/Actions/DocumentsActions";
import UploadFileToDoc from "./UploadFileToDoc";
import { MdDelete } from "react-icons/md";

function Document({ compInfo, item, index }) {
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState({
    isDisable: true,
    docID: ""
  });
  const [uploadIsVisible, setUploadIsVisible] = useState({
    visible: false,
    id: null
  });

  const toggleDisable = id => {
    setIsDisabled(val => {
      return {
        ...val,
        isDisable: !val.isDisable,
        docID: id
      };
    });
  };

  const handleUpdateDocument = async obj => {
    await dispatch(updateDocumentAction(obj));
  };

  const toggleUploadFileComponent = id => {
    setUploadIsVisible(obj => {
      return {
        ...obj,
        visible: !obj.visible,
        id: id
      };
    });
    // setUploadIsVisible(val => !val);
  };

  const handleDeleteDoc = async id => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this document?"
    );
    if (confirmation) {
      await dispatch(deleteDocAction(id));
    }
  };

  return (
    <div key={index} className="my-2 p-4">
      <Formik
        initialValues={{
          id: `${compInfo && compInfo.id}`,
          companyID: `${compInfo && compInfo.companyID}`,
          name: `${item.name}`,
          category: `${item.category}`
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          category: Yup.string().required("Category is required")
        })}
        onSubmit={values => {
          handleUpdateDocument(values);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          setFieldValue
        }) => (
          <>
            <Row>
              <Col lg="6">
                <label htmlFor="name">Document Name</label>
                <FormInput
                  id="name"
                  placeholder={item.name}
                  disabled={isDisabled}
                  value={values.name}
                  onChange={handleChange("name")}
                  onBlur={handleBlur("name")}
                />
                <small className="text-danger">
                  {" "}
                  {touched.name && errors.name}{" "}
                </small>
              </Col>
              <Col lg="6">
                <label htmlFor="category">Document Category</label>
                <FormInput
                  id="category"
                  placeholder={item.category}
                  disabled={isDisabled}
                  value={values.category}
                  onChange={handleChange("category")}
                  onBlur={handleBlur("category")}
                />
                <small className="text-danger">
                  {" "}
                  {touched.category && errors.category}{" "}
                </small>
              </Col>
            </Row>
            <Row>
              <Col
                lg="6"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Delete Document</Tooltip>}
                >
                  <MdDelete
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => handleDeleteDoc(item.id)}
                  />
                </OverlayTrigger>
              </Col>
              <Col md="6" className="text-right mt-3">
                <Button
                  className="my-3"
                  theme="accent"
                  onClick={
                    isDisabled ? () => toggleDisable(item.id) : handleSubmit
                  }
                >
                  {isDisabled.isDisable ? "Edit" : "Update"}
                </Button>
                <Button
                  outline
                  className="my-3 mx-2"
                  theme="accent"
                  onClick={() => toggleUploadFileComponent(item.id)}
                >
                  Upload File
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Formik>

      {uploadIsVisible.visible && uploadIsVisible.id == item.id ? (
        <UploadFileToDoc data={compInfo} refID={item.refID} />
      ) : null}
    </div>
  );
}

export default Document;
