import { GET_PAYMENTS } from "../types";
import { NotificationManager } from "react-notifications";
import { _getInvoice, _uploadProof } from "../../api/PaymentRequest";

export const getPaymentsInvoice = companyID => {
  return async dispatch => {
    try {
      const { data } = await _getInvoice(companyID);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_PAYMENTS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const uploadProofOfPayment = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _uploadProof(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
