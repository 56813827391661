export const GET_ALL_ADMIN = "GET_ALL_ADMIN";

export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const GET_ALL_LOGS = "GET_ALL_LOGS";

export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCS = "GET_DOCS";

export const GET_PAYMENTS = "GET_PAYMENTS";
