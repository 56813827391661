import {
  _createDocument,
  _deleteDocument,
  _getDocuments,
  _updateDocument,
  _uploadFileToDocument,
  _getDocs,
  _deleteFile
} from "../../api/DocumentsRequest";
import { NotificationManager } from "react-notifications";
import { GET_DOCUMENTS, GET_DOCS } from "../types";

export const getDocuments = companyID => {
  return async dispatch => {
    try {
      const { data } = await _getDocuments(companyID);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_DOCUMENTS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const createDocumentAction = obj => {
  return async dispatch => {
    try {
      const { name, companyID, category } = obj;

      const reqObj = {
        data: {
          name,
          companyID,
          category
        }
      };

      const { data } = await _createDocument(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateDocumentAction = obj => {
  return async dispatch => {
    try {
      const { name, companyID, category, id } = obj;

      const reqObj = {
        data: {
          name,
          id: parseInt(id),
          companyID,
          category
        }
      };

      const { data } = await _updateDocument(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        dispatch(getDocuments(companyID));
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const uploadFileToDocAction = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _uploadFileToDocument(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const deleteDocAction = id => {
  return async dispatch => {
    try {
      const reqObj = {
        data: {
          docID: id
        }
      };
      const { data } = await _deleteDocument(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const deleteFileAction = obj => {
  return async dispatch => {
    try {
      const { id, url } = obj;
      const reqObj = {
        data: {
          docId: id,
          url
        }
      };
      const { data } = await _deleteFile(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const getDocs = reqObj => {
  return async dispatch => {
    try {
      const obj = {
        companyID: reqObj.companyID || "-",
        refID: reqObj.refID || "-"
      };
      const { data } = await _getDocs(obj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_DOCS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const getFilteredDocs = reqObj => {
  return async dispatch => {
    try {
      const obj = {
        companyID: reqObj.companyID || "-",
        refID: reqObj.refID || "-"
      };
      const { data } = await _getDocs(obj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_DOCS, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
