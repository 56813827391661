import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import IndustryProfileDetails from "../../components/industry-manager/IndustryProfileDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompany,
  updateCompanyBasicInfoAction
} from "../../store/Actions/CompanyActions";
import CompanyProfileCard from "./CompanyProfileCard";

function Company({ props }) {
  const dispatch = useDispatch();
  const userProf = JSON.parse(sessionStorage.getItem("otheruserProfile"));
  const companyProfile = useSelector(state => state.Companies.company);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-2">
        <PageTitle
          title={companyProfile && companyProfile.name}
          subtitle=""
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="4">
          {userProf && <CompanyProfileCard companyDetails={userProf} />}
        </Col>

        <Col lg="8">
          {companyProfile && (
            <IndustryProfileDetails industryDetails={companyProfile} />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(Company);
