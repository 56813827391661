import { NotificationManager } from "react-notifications";
import {
  _createCompany,
  _getAllCompanies,
  _getCompany,
  _updateBasicCompanyInfo,
  _updateCompanyConsultant,
  _updateCompanyEmployee,
  _updateCompanyMedicalPartner
} from "../../api/CompanyRequest";
import { GET_ALL_COMPANIES, GET_COMPANY } from "../types";

export const createCompanyAction = obj => {
  return async dispatch => {
    try {
      const { name, lg, rc, phone, email, address } = obj;

      const reqObj = {
        data: {
          name,
          lg,
          rc,
          phone,
          email,
          address
        }
      };
      const { data } = await _createCompany(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      return -1;
    }
  };
};

export const getAllCompanies = () => {
  return async dispatch => {
    try {
      const { data } = await _getAllCompanies();

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_ALL_COMPANIES, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const getCompany = companyID => {
  return async dispatch => {
    try {
      const { data } = await _getCompany(companyID);
      // console.log("success login ", data);
      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        dispatch({ type: GET_COMPANY, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("companiesa error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateCompanyBasicInfoAction = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _updateBasicCompanyInfo(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateCompanyEmployeeAction = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _updateCompanyEmployee(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateCompanyMedicalPartner = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _updateCompanyMedicalPartner(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const updateCompanyConsultant = reqObj => {
  return async dispatch => {
    try {
      const { data } = await _updateCompanyConsultant(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        //   dispatch({ type: GET_ALL_ADMIN, payload: data.content.data });
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("admin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
