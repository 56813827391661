import React from "react";
import { Icon } from "@iconify/react";
import mapMarker from "@iconify/icons-mdi/map-marker";

function LocationMarker({ lat, lng, onClick }) {
  return (
    <div className="location-marker">
      <Icon
        icon={mapMarker}
        className="location-icon"
        style={{ fontSize: "1.5rem", color: "red" }}
      />
    </div>
  );
}

export default LocationMarker;
