import React from "react";
import logo from "../../images/avatars/lasepa-logo.png";

function NavBar() {
  return (
    <div
      style={{
        // position: "fixed",
        // top: 0,
        // left: 0,
        // right: 0,
        width: "100%",
        backgroundColor: "#046E34",
        color: "white",
        display: "flex",
        alignItems: "center"
      }}
    >
      <img src={logo} style={{ maxWidth: "250px" }} />
    </div>
  );
}

export default NavBar;
