import { _signInRequest } from "../../api/AuthRequest";
import { NotificationManager } from "react-notifications";
import { _reportPollution } from "../../api/ReportRequest";

export const reportPollutionAction = obj => {
  return async dispatch => {
    try {
      const { data } = await _reportPollution(obj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("report pollution error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};
