import React, { useState } from "react";
import { Card, CardHeader, CardBody, Col } from "shards-react";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteFileAction } from "../../../store/Actions/DocumentsActions";
import { Link } from "react-router-dom";
import moment from "moment";
import { baseUrl } from "../../../api";

function ImageCard({ data, key }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDeleteFile = async obj => {
    setLoading(true);
    const res = window.confirm("Are you sure you want to delete this file?");
    if (res) {
      const response = await dispatch(
        deleteFileAction({ id: data.id, url: data.url })
      );
      if (response) setLoading(false);
    }
  };
  return (
    <Col md="3" key={key}>
      <div style={{ position: "relative" }}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Delete File</Tooltip>}
        >
          <MdDelete
            style={{
              cursor: "pointer",
              color: "red",
              position: "absolute",
              top: 10,
              right: 10
            }}
            onClick={handleDeleteFile}
          >
            {loading && <Spinner variant="grow" />}
          </MdDelete>
        </OverlayTrigger>

        <div>
          <div>
            <Link
              to={{ pathname: `${baseUrl}/${data.url}` }}
              target="_blank"
              className="p-0 m-0 "
              style={{ fontSize: "1.5rem", color: "#495057" }}
            >
              {data.docName}
            </Link>
            <p> {data.note}</p>
          </div>
          <p className="p-0 m-0" style={{ fontSize: ".9rem" }}>
            Created by {data.creator} at{" "}
            {moment(data.dateCreated * 1000).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
    </Col>
  );
}

export default ImageCard;
