import React, { useState } from "react";
import { Col, Row, Form, FormInput, Button, FormSelect } from "shards-react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { createDocumentAction } from "../../../store/Actions/DocumentsActions";
import { NotificationManager } from "react-notifications";

function RequestToSubmitModal({ open, toggle, handleClose }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const compInfo = JSON.parse(sessionStorage.getItem("otheruserProfile"));

  const handleCreateDoc = async val => {
    setLoading(true);
    setTimeout(() => {
      NotificationManager.success("Request Sent", "Success");
      setLoading(false);
      handleClose();
    }, 5000);
  };

  return (
    // <Modal open={open} toggle={toggle} className="modal-overflow">
    <Modal show={open} onHide={handleClose}>
      <Modal.Header> Request to Submit Document </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                companyID: ``,
                name: "test name",
                category: "",
                docType: ""
              }}
              validationSchema={yup.object({
                name: yup.string().required("Document name is required"),
                category: yup.string().required("Category is required")
              })}
              onSubmit={values => {
                handleCreateDoc(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="name">Consultant name</label>
                      <FormInput
                        id="name"
                        type="text"
                        placeholder={`${compInfo.name}`}
                        value={compInfo.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                        disabled
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.name && errors.name}{" "}
                      </small>
                    </Col>
                  </Row>{" "}
                  <Row>
                    <Col lg="12">
                      <label htmlFor="category">Report Category</label>
                      <FormSelect
                        id="category"
                        onChange={handleChange("category")}
                        onBlur={handleBlur("category")}
                        value={values.category}
                      >
                        <option value="">Select Category</option>
                        <option value="EA">
                          Environmental Assessment Report (EA)
                        </option>
                        <option value="EAR">Environmental Audit Report</option>
                        <option value="EMP">
                          Environmental Management Plan
                        </option>
                        <option value="EIA">
                          Environmental Impact Analysis
                        </option>
                      </FormSelect>
                      <small className="text-danger">
                        {" "}
                        {touched.category && errors.category}{" "}
                      </small>
                      {/* <FormInput
                        id="category"
                        type="text"
                        placeholder="Category"
                        value={values.category}
                        onChange={handleChange("category")}
                        onBlur={handleBlur("category")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.category && errors.category}{" "}
                      </small> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        className="mx-2 my-2"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default RequestToSubmitModal;
