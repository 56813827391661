import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";

const WaterDetailsForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Water Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  source: "",
                  prodVol: "",
                  domVol: "",
                  maxVol: ""
                }}
                validationSchema={Yup.object({
                  source: Yup.string(),
                  prodVol: Yup.string(),
                  domVol: Yup.string(),
                  maxVol: Yup.string()
                })}
                onSubmit={values => {
                  // console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4" className="form-group">
                        <label htmlFor="source">Source of Water Used</label>
                      </Col>
                      <Col md="8">
                        <FormSelect
                          id="source"
                          onChange={handleChange("source")}
                          onBlur={handleBlur("source")}
                          value={values.source}
                        >
                          <option value="">Select source</option>
                          <option value="Borehole">Borehole</option>
                          <option value="Water Corporation">
                            Water Corporation
                          </option>
                          <option value="Both">Both</option>
                        </FormSelect>
                        <small className="text-danger">
                          {" "}
                          {touched.source && errors.source}{" "}
                        </small>
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="prodVol">
                          Maximum Volume Per Day in Production Activities
                        </label>
                      </Col>

                      <Col md="8">
                        <FormInput
                          id="prodVol"
                          placeholder="Max Volume/Day"
                          value={values.prodVol}
                          onChange={handleChange("prodVol")}
                          onBlur={handleBlur("prodVol")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.prodVol && errors.prodVol}{" "}
                        </small>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="domVol">
                          Maximum Volume Per Day in Domestic Activities
                        </label>
                      </Col>

                      <Col md="8">
                        <FormInput
                          id="domVol"
                          placeholder="Max Volume/Day"
                          value={values.domVol}
                          onChange={handleChange("domVol")}
                          onBlur={handleBlur("domVol")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.domVol && errors.domVol}{" "}
                        </small>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="maxVol">
                          Total Maximum Volume of Daily water use
                        </label>
                      </Col>

                      <Col md="8">
                        <FormInput
                          id="maxVol"
                          placeholder="Total Volume"
                          value={values.maxVol}
                          onChange={handleChange("maxVol")}
                          onBlur={handleBlur("maxVol")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.maxVol && errors.maxVol}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="text-right mt-3">
                        <Button
                          className="my-3"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
WaterDetailsForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

WaterDetailsForm.defaultProps = {
  title: "Account Details"
};

export default WaterDetailsForm;
