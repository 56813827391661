import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, FormSelect, Button } from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  getDocs,
  getFilteredDocs
} from "../../../store/Actions/DocumentsActions";
import ImageCard from "./ImageCard";

function PreviewDocs({ data }) {
  const [loading, setLoading] = useState(false);
  const docCategories = useSelector(state => state.Documents.documents);
  const docs = useSelector(state => state.Documents.docs);

  const dispatch = useDispatch();

  useEffect(() => {
    async function callGetDocs() {
      if (data) {
        const obj = {
          companyID: data.companyID,
          refID: "-"
        };

        await dispatch(getDocs(obj));
      }
    }
    callGetDocs();
  }, []);

  const handleFilterDocs = async values => {
    setLoading(true);
    const response = await dispatch(getFilteredDocs(values));
    if (response) {
      setLoading(false);
    }
  };

  return (
    <Card className="p-4">
      <Row>
        <Col>
          <Formik
            initialValues={{
              refID: "-",
              companyID: data && data.companyID
            }}
            validationSchema={Yup.object({
              refID: Yup.string()
            })}
            onSubmit={values => {
              handleFilterDocs(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              setFieldValue
            }) => (
              <Form>
                <Row>
                  <Col md="4">
                    <label htmlFor="refID">Filter by</label>
                    {/* </Col>
                      <Col md="4"> */}
                    <FormSelect
                      id="refID"
                      onChange={handleChange("refID")}
                      onBlur={handleBlur("refID")}
                      value={values.refID}
                    >
                      <option value="">All documents</option>
                      {docCategories &&
                        docCategories.map((item, index) => (
                          <option key={index} value={item.refID}>
                            {item.name} | {item.category}
                          </option>
                        ))}
                    </FormSelect>
                    <small className="text-danger">
                      {" "}
                      {touched.refID && errors.refID}{" "}
                    </small>
                  </Col>
                  <Col md="1" className="text-right ">
                    <Button disabled={loading} onClick={handleSubmit}>
                      Filter
                    </Button>
                  </Col>
                </Row>
                <hr />
              </Form>
            )}
          </Formik>
        </Col>
      </Row>

      <Row>
        {docs &&
          docs.map((item, index) => {
            return <ImageCard data={item} key={index} />;
          })}
      </Row>
    </Card>
  );
}

export default PreviewDocs;
