import React, { useState } from "react";
import {
  Col,
  Row,
  // Modal,
  // ModalHeader,
  // ModalBody,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
  // ModalFooter
} from "shards-react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import { createStaffAction } from "../../store/Actions/AdminActions";

function CreateStaffModal({ open, toggle, handleClose }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async val => {
    setLoading(true);
    const response = await dispatch(createStaffAction(val));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    // <Modal open={open} toggle={toggle} className="modal-overflow">
    <Modal show={open} onHide={handleClose}>
      <Modal.Header> Create Staff </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                fullname: "",
                username: "",
                phone: "",
                address: "",
                email: "",
                password: "",
                gender: "",
                usertype: "",
                staffID: "",
                unit: ""
              }}
              validationSchema={yup.object({
                fullname: yup.string().required("Fullname is required"),
                username: yup.string().required("Username is required"),
                phone: yup.string().required("Phone number is required"),
                address: yup.string().required("Address is required"),
                email: yup
                  .string()
                  .email()
                  .required("Email is required"),
                password: yup.string().required("Password is required"),
                gender: yup.string().required("Gender is required"),
                usertype: yup.string().required("User type is required"),
                staffID: yup.string().required("StaffID is required"),
                unit: yup.string().required("Unit is required")
              })}
              onSubmit={values => {
                handleSubmit(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="6">
                      <label htmlFor="fullname">Fullname</label>
                      <FormInput
                        id="fullname"
                        type="text"
                        placeholder="Fullname"
                        value={values.fullname}
                        onChange={handleChange("fullname")}
                        onBlur={handleBlur("fullname")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.fullname && errors.fullname}{" "}
                      </small>
                    </Col>

                    <Col lg="6">
                      <label htmlFor="username">Username</label>
                      <FormInput
                        id="username"
                        type="text"
                        placeholder="Username"
                        value={values.username}
                        onChange={handleChange("username")}
                        onBlur={handleBlur("username")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.username && errors.username}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="6">
                      <label htmlFor="phone">Phone</label>
                      <FormInput
                        id="phone"
                        type="text"
                        placeholder="phone"
                        value={values.phone}
                        onChange={handleChange("phone")}
                        onBlur={handleBlur("phone")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.phone && errors.phone}{" "}
                      </small>
                    </Col>

                    <Col lg="6">
                      <label htmlFor="gender">Gender</label>

                      <FormSelect
                        id="gender"
                        value={values.gender}
                        onChange={handleChange("gender")}
                        onBlur={handleBlur("gender")}
                      >
                        <option value="">Select Gender...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </FormSelect>
                      <small className="text-danger">
                        {" "}
                        {touched.gender && errors.gender}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="6">
                      <label htmlFor="email">Email</label>
                      <FormInput
                        id="email"
                        type="email"
                        placeholder="email"
                        value={values.email}
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.email && errors.email}{" "}
                      </small>
                    </Col>

                    <Col lg="6">
                      <label htmlFor="password">Password</label>
                      <FormInput
                        id="password"
                        type="password"
                        placeholder="password"
                        value={values.password}
                        onChange={handleChange("password")}
                        onBlur={handleBlur("password")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.password && errors.password}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="6">
                      <label htmlFor="staffID">Staff ID</label>
                      <FormInput
                        id="staffID"
                        type="text"
                        placeholder="Staff ID"
                        value={values.staffID}
                        onChange={handleChange("staffID")}
                        onBlur={handleBlur("staffID")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.staffID && errors.staffID}{" "}
                      </small>
                    </Col>

                    <Col lg="6">
                      <label htmlFor="usertype">Access Level</label>

                      <FormSelect
                        id="usertype"
                        value={values.usertype}
                        onChange={handleChange("usertype")}
                        onBlur={handleBlur("usertype")}
                      >
                        <option value="">Select Access Level...</option>
                        <option value="1">Admin</option>
                        <option value="2">User</option>
                      </FormSelect>
                      <small className="text-danger">
                        {" "}
                        {touched.usertype && errors.usertype}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="unit">Unit</label>
                      <FormInput
                        id="unit"
                        type="text"
                        value={values.unit}
                        onChange={handleChange("unit")}
                        onBlur={handleBlur("unit")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.unit && errors.unit}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="address">Address</label>

                      <FormTextarea
                        id="address"
                        rows="3"
                        value={values.address}
                        onChange={handleChange("address")}
                        onBlur={handleBlur("address")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.address && errors.address}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        className="mx-2 my-2"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        Create Staff
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button outline theme="info" type="submit" onClick={handleClose}>
          Close
        </Button>
        <Button type="submit">Create Staff</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default CreateStaffModal;
