import React, { useEffect, useState } from "react";
import { Card, ListGroup, ListGroupItem, Row, Col, Button } from "shards-react";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../store/Actions/DocumentsActions";
import NewDocCategoryModal from "./NewDocCategoryModal";
import Document from "./Document";

const Documents = ({ state }) => {
  const [compInfo, setCompInfo] = useState(null);
  const [openNewDocModal, setOpenNewDocModal] = useState(false);
  const dispatch = useDispatch();
  const documents = useSelector(state => state.Documents.documents);

  const toggleNewDocModal = () => {
    setOpenNewDocModal(true);
  };

  const closeNewDocModal = () => {
    setOpenNewDocModal(val => !val);
  };

  useEffect(() => {
    try {
      const companyInfo = JSON.parse(localStorage.getItem("otheruserProfile"));
      const { companyID } = companyInfo;
      setCompInfo(companyInfo);
      dispatch(getDocuments(companyID));
    } catch (error) {
      console.log("documents error", error);
    }

    return () => {};
  }, []);

  return (
    <div small className="">
      <NewDocCategoryModal
        open={openNewDocModal}
        handleClose={closeNewDocModal}
      />

      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Row>
                <Col md="12" className="text-right ">
                  <Button onClick={toggleNewDocModal} theme="accent">
                    Create New Category
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              {documents.length > 0 ? (
                documents.map((item, index) => {
                  return (
                    <Card key={index} className="my-2 p-4">
                      <Document index={index} item={item} compInfo={compInfo} />
                    </Card>
                  );
                })
              ) : (
                <p>No documents available... Please create a new document</p>
              )}
              {/* <Card></Card> */}
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default Documents;
