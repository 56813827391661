import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { getPaymentsInvoice } from "../../store/Actions/PaymentActions";
import Invoice from "./Invoice";
import { Spinner } from "react-bootstrap";

function Payment() {
  const userProf = JSON.parse(sessionStorage.getItem("otheruserProfile"));
  const payments = useSelector(state => state.Payments.payments);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function callGetInvoice() {
      if (userProf) {
        setLoading(true);
        await dispatch(getPaymentsInvoice(userProf.companyID));
        setLoading(false);
      }
    }
    callGetInvoice();
  }, []);
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-2">
        <PageTitle
          title="Invoice Module"
          subtitle=""
          className="text-sm-left"
        />
      </Row>

      {/* <Row className="my-4">
        {payments.length > 0 ? (
          payments.map((item, index) => {
            return <Invoice invoice={item} index={index} />;
          })
        ) : payments.length === 0 ? (
          <p>Payment history is loading...</p>
        ) : (
          <Spinner animation="border" variant="secondary" size="sm" />
        )}
      </Row> */}

      <Row className="my-4">
        {loading ? (
          <Spinner animation="border" variant="secondary" size="sm" />
        ) : Boolean(payments) ? (
          payments.map((item, index) => {
            return <Invoice invoice={item} index={index} />;
          })
        ) : (
          <p> No Payments Available! </p>
        )}

        {/* {payments.length > 0 ? (
          payments.map((item, index) => {
            return <Invoice invoice={item} index={index} />;
          })
        ) : (
          <Spinner animation="border" variant="secondary" size="sm" />
        )} */}
      </Row>
    </Container>
  );
}

export default Payment;
