import React from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import PollutionManagementForm from "./PollutionManagementForm";

function PollutionManagement() {
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Air and noise pollution management"
          className="text-sm-left mb-3"
        />
      </Row>

      <Row>
        <Col lg="12" className="mb-4">
          <PollutionManagementForm />
        </Col>
      </Row>
    </Container>
  );
}

export default PollutionManagement;
