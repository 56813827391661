import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import AdminReducer from "./AdminReducer";
import CompanyReducer from "./CompanyReducer";
import ActivitiesLogReducer from "./ActivitiesLogReducer";
import DocumentsReducer from "./DocumentsReducer";
import PaymentReducer from "./PaymentReducer";

const reducers = combineReducers({
  Auth: AuthReducer,
  Admin: AdminReducer,
  Companies: CompanyReducer,
  Logs: ActivitiesLogReducer,
  Documents: DocumentsReducer,
  Payments: PaymentReducer
});

export default reducers;
