import React from "react";
import { Row, Col, Form, FormInput, Button } from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateCompanyEmployeeAction } from "../../store/Actions/CompanyActions";

function EmployeeInfoTab({ data }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleUpdateCompanyEmp = async values => {
    setLoading(true);
    const obj = {
      data: {
        ...values
      }
    };
    const response = await dispatch(updateCompanyEmployeeAction(obj));
    if (response) {
      setLoading(false);
    }
  };
  return (
    <div className="p-4">
      <Row>
        <Col>
          <Formik
            initialValues={{
              id: `${data && data.id}`,
              expatriate: "",
              permanent: "",
              casual: "",
              industrial: "",
              nysc: "",
              companyID: `${data && data.companyID}`
            }}
            validationSchema={Yup.object({
              expatriate: Yup.string(),
              permanent: Yup.string()
                .trim()
                .required("Required"),
              casual: Yup.string()
                .trim()
                .required("Required"),
              industrial: Yup.string(),
              nysc: Yup.string()
            })}
            onSubmit={values => {
              handleUpdateCompanyEmp(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors
            }) => (
              <Form>
                {/* <Row form>
                 */}

                {/* Employee Expatriate */}
                <Row>
                  <Col>
                    <h5>Employee Expatriate</h5>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="12">
                    <FormInput
                      id="expatriate"
                      type="number"
                      placeholder="Expatriate"
                      value={values.expatriate}
                      onChange={handleChange("expatriate")}
                      onBlur={handleBlur("expatriate")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.expatriate && errors.expatriate}{" "}
                    </small>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="12">
                    <FormInput
                      id="permanent"
                      type="number"
                      placeholder="Permanent"
                      value={values.permanent}
                      onChange={handleChange("permanent")}
                      onBlur={handleBlur("permanent")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.permanent && errors.permanent}{" "}
                    </small>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="12">
                    <FormInput
                      id="casual"
                      type="number"
                      placeholder="Casual"
                      value={values.casual}
                      onChange={handleChange("casual")}
                      onBlur={handleBlur("casual")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.casual && errors.casual}{" "}
                    </small>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="12">
                    <FormInput
                      id="industrial"
                      type="number"
                      placeholder="Industrial"
                      value={values.industrial}
                      onChange={handleChange("industrial")}
                      onBlur={handleBlur("industrial")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.industrial && errors.industrial}{" "}
                    </small>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="12">
                    <FormInput
                      id="nysc"
                      type="number"
                      placeholder="NYSC"
                      value={values.nysc}
                      onChange={handleChange("nysc")}
                      onBlur={handleBlur("nysc")}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.nysc && errors.nysc}{" "}
                    </small>
                  </Col>
                </Row>

                <Button theme="accent" onClick={handleSubmit}>
                  {loading && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                  &nbsp; Update Profile
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default EmployeeInfoTab;
