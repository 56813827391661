import { _companySignInRequest, _signInRequest } from "../../api/AuthRequest";
import { NotificationManager } from "react-notifications";

export const authActions = () => {
  return dispatch => {
    dispatch({ type: "AUTH", payload: true });
  };
};

export const signInAction = obj => {
  return async dispatch => {
    try {
      const reqObj = {
        data: {
          username: obj.username,
          password: obj.password
        }
      };
      const { data } = await _signInRequest(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        setAuthorization(data.content);
        NotificationManager.success(data.success.message, "Success");
        return 1;
      }
    } catch (error) {
      console.log("signin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

export const otherSignInAction = obj => {
  return async dispatch => {
    try {
      const reqObj = {
        data: {
          rcn: obj.rcn,
          companyID: obj.companyID
        }
      };
      const { data } = await _companySignInRequest(reqObj);

      if (data.error.status == 1) {
        NotificationManager.error(data.error.message, "Error");
        return -1;
      } else {
        setAuthorization(data.content);
        NotificationManager.success(data.success.message, "Success");
        return data.content.data[0];
      }
    } catch (error) {
      console.log("signin error", error);
      NotificationManager.error(error.message, "Error");
      return -1;
    }
  };
};

const setAuthorization = content => {
  if (content) {
    sessionStorage.setItem("othertoken", content.token);
    sessionStorage.setItem("otheruserProfile", JSON.stringify(content.data[0]));
  }
};

export const logoutUser = () => {
  return dispatch => {
    sessionStorage.clear();
    localStorage.clear();
    NotificationManager.success("Logout successful", "Success");
  };
};
