import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ProductionDetailsForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Production Details and Material Balance</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  machinesDetails: "",
                  rawMaterials: [{ material: "", qty: "" }],
                  products: [{ material: "", qty: "" }]
                }}
                validationSchema={Yup.object({
                  machinesDetails: Yup.string(),
                  rawMaterials: Yup.array()
                    .of(
                      Yup.object().shape({
                        material: Yup.string().min(
                          2,
                          "Must be greater than 2 characters"
                        ),
                        qty: Yup.string()
                      })
                    )
                    .required("Must have at least one Raw Material"),
                  products: Yup.array()
                    .of(
                      Yup.object().shape({
                        material: Yup.string(),
                        qty: Yup.string()
                      })
                    )
                    .required("Must have at least one Raw Material")
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4">
                        <label htmlFor="machinesDetails">
                          A. &nbsp; Machine Designs and Ergonomics
                        </label>
                      </Col>
                      <Col md="8">
                        <FormTextarea
                          placeholder="Please enter list of machines, designs and ergonomics"
                          id="machinesDetails"
                          rows="5"
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md="4">
                        <label htmlFor="rawMaterials">
                          B. &nbsp; Raw Materials Input
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="rawMaterials">
                          {({ push, remove }) => (
                            <>
                              {values.rawMaterials.map((item, index) => {
                                const materialName = `rawMaterials[${index}].material`;
                                const qtyName = `rawMaterials[${index}].qty`;
                                const materialError = getIn(
                                  errors,
                                  materialName
                                );
                                const qtyError = getIn(errors, qtyName);
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormInput
                                        id="rawMaterials"
                                        name={materialName}
                                        placeholder="Include Raw Materials"
                                        value={item.material}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2"
                                      />
                                      {materialError && (
                                        <small className="text-danger">
                                          {materialError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="rawMaterials"
                                        name={qtyName}
                                        placeholder="Quantity"
                                        value={item.qty}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {qtyError && (
                                        <small className="text-danger">
                                          {qtyError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({ material: "", qty: "" })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="products">
                          C. &nbsp; Product(s) Output
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="products">
                          {({ push, remove }) => (
                            <>
                              {values.products.map((item, index) => {
                                const materialName = `products[${index}].material`;
                                const qtyName = `products[${index}].qty`;
                                const materialError = getIn(
                                  errors,
                                  materialName
                                );
                                const qtyError = getIn(errors, qtyName);
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormInput
                                        id="products"
                                        name={materialName}
                                        placeholder="Include Raw Materials"
                                        value={item.material}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2"
                                      />
                                      {materialError && (
                                        <small className="text-danger">
                                          {materialError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="products"
                                        name={qtyName}
                                        placeholder="Quantity"
                                        value={item.qty}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {qtyError && (
                                        <small className="text-danger">
                                          {qtyError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({ material: "", qty: "" })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="materialBalance">
                          D. &nbsp; Material Balance Calculations
                        </label>
                      </Col>
                      <Col md="4">
                        <label htmlFor="materialBalanceInput">
                          Total Input per day
                        </label>
                        <FormInput
                          id="materialBalanceInput"
                          placeholder=" Total Input per day"
                          value={values.materialBalanceInput}
                          onChange={handleChange("materialBalanceInput")}
                          onBlur={handleBlur("materialBalanceInput")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.materialBalanceInput &&
                            errors.materialBalanceInput}{" "}
                        </small>
                      </Col>

                      <Col md="4">
                        <label htmlFor="materialBalanceOutput">
                          Total Output per day
                        </label>
                        <FormInput
                          id="materialBalanceOutput"
                          placeholder="Total Output per day"
                          value={values.materialBalanceOutput}
                          onChange={handleChange("materialBalanceOutput")}
                          onBlur={handleBlur("materialBalanceOutput")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.materialBalanceOutput &&
                            errors.materialBalanceOutput}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="text-right mt-3">
                        <Button
                          className="my-3"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
ProductionDetailsForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ProductionDetailsForm.defaultProps = {
  title: "Account Details"
};

export default ProductionDetailsForm;
