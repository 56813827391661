import React from "react";
import { Card, CardHeader } from "shards-react";
import { Tabs, Tab } from "react-bootstrap";
import CompanyInfoTab from "./CompanyInfoTab";
import EmployeeInfoTab from "./EmployeeInfoTab";
import MedicalPartnerInfoTab from "./MedicalPartnerInfoTab";
import ConsultantInfoTab from "./ConsultantInfoTab";
import SummaryTab from "./SummaryTab";

const IndustryProfileDetails = ({ industryDetails }) => (
  <Card small className="my-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">Other Company Details</h6>
    </CardHeader>
    <Tabs defaultActiveKey="compInfo">
      <Tab
        eventKey="compInfo"
        title="Company Information"
        tabClassName="font-weight-bold p-3"
      >
        <CompanyInfoTab data={industryDetails} />
      </Tab>
      <Tab
        eventKey="empInfo"
        title="Employee Information"
        tabClassName="font-weight-bold p-3"
      >
        <EmployeeInfoTab data={industryDetails} />
      </Tab>
      <Tab
        eventKey="medPart"
        title="Medical Partners"
        tabClassName="font-weight-bold p-3"
      >
        <MedicalPartnerInfoTab data={industryDetails} />
      </Tab>
      <Tab
        eventKey="consultant"
        title="Consultants"
        tabClassName="font-weight-bold p-3 text-center"
      >
        <ConsultantInfoTab data={industryDetails} />
      </Tab>

      <Tab
        eventKey="summary"
        title="Summary"
        tabClassName="font-weight-bold p-3 text-center"
      >
        <SummaryTab />
      </Tab>
    </Tabs>
  </Card>
);

export default IndustryProfileDetails;
