import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, SubDefaultLayout } from "./layouts";

// Route Views
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import IndustryManager from "./views/IndustryManager";
import IndustryDetails from "./views/IndustryManager/IndustryDetails";
import ReportNoise from "./views/ReportNoise";
import ActivitiesLog from "./views/ActivitiesLog";
import Legal from "./views/IndustryManager/Legal";
import ProductionDetails from "./views/IndustryManager/ProductionDetails";
import WarehouseDetails from "./views/IndustryManager/WarehouseDetails";
import EnergyDetails from "./views/IndustryManager/EnergyDetails";
import CarbonEmissions from "./views/IndustryManager/CarbonEmissions";
import StorageTank from "./views/IndustryManager/StorageTank";
import WaterDetails from "./views/IndustryManager/WaterDetails";
import PollutionManagement from "./views/IndustryManager/PollutionManagement";
import BakeHouse from "./views/IndustryManager/BakeHouse";
import WasteManagement from "./views/IndustryManager/WasteManagement";
import TechnicalDetails from "./views/IndustryManager/TechnicalDetails.js";
import Compliance from "./views/IndustryManager/Compliance";
import Typographical from "./views/IndustryManager/Typographical";
import Admin from "./views/Admin";
import Attachement from "./views/IndustryManager/Attachement";
import Company from "./views/Company";
import Consultant from "./views/Consultant";
import Payment from "./views/Payment";
import CompanyProfile from "./views/CompanyProfile";
import DocumentManager from "./views/DocumentManager";

export default (id, usertype) => {
  return [
    {
      path: "/",
      exact: true,
      layout: DefaultLayout,
      component: () => <Redirect to="/company" />
    },

    {
      path: "/company",
      exact: true,
      layout: DefaultLayout,
      component: Company
    },
    {
      path: "/companypayment",
      exact: true,
      layout: DefaultLayout,
      component: Payment
    },
    {
      path: "/document-manager",
      exact: true,
      layout: DefaultLayout,
      component: DocumentManager
    },
    {
      path: "/companyprofile",
      exact: true,
      layout: DefaultLayout,
      component: CompanyProfile
    },

    {
      path: "/company-manager",
      exact: true,
      layout: DefaultLayout,
      component: IndustryManager
    },
    {
      path: "/consultant",
      exact: true,
      layout: SubDefaultLayout,
      component: Company
    },
    {
      path: "/consultantpayment",
      exact: true,
      layout: SubDefaultLayout,
      component: Payment
    },
    {
      path: "/consultant-docmanager",
      exact: true,
      layout: SubDefaultLayout,
      component: DocumentManager
    },
    {
      path: "/consultantprofile",
      exact: true,
      layout: SubDefaultLayout,
      component: CompanyProfile
    },

    {
      path: "/company-manager/details/:id",
      exact: true,
      layout: SubDefaultLayout,
      component: IndustryDetails
    },
    {
      path: "/company-manager/details/:id/legal",
      exact: true,
      layout: SubDefaultLayout,
      component: Legal
    },
    {
      path: `/company-manager/details/:id/prod-details`,
      exact: true,
      layout: SubDefaultLayout,
      component: ProductionDetails
    },
    {
      path: `/company-manager/details/:id/warehouse-details`,
      exact: true,
      layout: SubDefaultLayout,
      component: WarehouseDetails
    },
    {
      path: `/company-manager/details/:id/energy-details`,
      exact: true,
      layout: SubDefaultLayout,
      component: EnergyDetails
    },
    {
      path: `/company-manager/details/:id/carbon-emissions`,
      exact: true,
      layout: SubDefaultLayout,
      component: CarbonEmissions
    },
    {
      path: `/company-manager/details/:id/storage-tank`,
      exact: true,
      layout: SubDefaultLayout,
      component: StorageTank
    },
    {
      path: `/company-manager/details/:id/water-details`,
      exact: true,
      layout: SubDefaultLayout,
      component: WaterDetails
    },

    {
      path: `/company-manager/details/:id/pollution-mgmt`,
      exact: true,
      layout: SubDefaultLayout,
      component: PollutionManagement
    },
    {
      path: `/company-manager/details/:id/bakehouse`,
      exact: true,
      layout: SubDefaultLayout,
      component: BakeHouse
    },
    {
      path: `/company-manager/details/:id/waste-mgmt`,
      exact: true,
      layout: SubDefaultLayout,
      component: WasteManagement
    },
    {
      path: `/company-manager/details/:id/other-tech`,
      exact: true,
      layout: SubDefaultLayout,
      component: TechnicalDetails
    },
    {
      path: `/company-manager/details/:id/other-compliance`,
      exact: true,
      layout: SubDefaultLayout,
      component: Compliance
    },
    {
      path: `/company-manager/details/:id/typographical-details`,
      exact: true,
      layout: SubDefaultLayout,
      component: Typographical
    },
    {
      path: `/company-manager/details/:id/attachements`,
      exact: true,
      layout: SubDefaultLayout,
      component: Attachement
    },
    {
      path: "/report-noise",
      exact: true,
      layout: SubDefaultLayout,
      component: ReportNoise
    },
    {
      path: "/activities-log",
      exact: true,
      layout: DefaultLayout,
      component: ActivitiesLog
    },
    {
      path: "/admin",
      exact: true,
      layout: DefaultLayout,
      component: Admin
    },
    {
      path: "/user-profile-lite",
      exact: true,
      layout: SubDefaultLayout,
      component: UserProfileLite
    },
    {
      path: "/add-new-post",
      exact: true,
      layout: SubDefaultLayout,
      component: AddNewPost
    },
    {
      path: "/errors",
      layout: SubDefaultLayout,
      component: Errors
    },
    {
      path: "/components-overview",
      layout: SubDefaultLayout,
      component: ComponentsOverview
    },
    {
      path: "/tables",
      layout: SubDefaultLayout,
      component: Tables
    },
    {
      path: "/blog-posts",
      layout: SubDefaultLayout,
      component: BlogPosts
    }
    // {
    //   path: "/report-noise",
    //   exact: true,
    //   layout: DefaultLayout,
    //   component: ReportNoise
    // },

    // {
    //   path: "/user-profile-lite",
    //   exact: true,
    //   layout: DefaultLayout,
    //   component: UserProfileLite
    // },
    // {
    //   path: "/add-new-post",
    //   exact: true,
    //   layout: DefaultLayout,
    //   component: AddNewPost
    // },
    // {
    //   path: "/errors",
    //   layout: DefaultLayout,
    //   component: Errors
    // },
    // {
    //   path: "/components-overview",
    //   layout: DefaultLayout,
    //   component: ComponentsOverview
    // },
    // {
    //   path: "/tables",
    //   layout: DefaultLayout,
    //   component: Tables
    // },
    // {
    //   path: "/blog-posts",
    //   layout: DefaultLayout,
    //   component: BlogPosts
    // }
  ];
};
