import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Container, Row, Button } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import IndustryManagerList from "../../components/industry-manager/IndustryManagerList";
import AddIndustry from "./AddIndustry";
import { useDispatch, useSelector } from "react-redux";
import { getAllCompanies } from "../../store/Actions/CompanyActions";

const IndustryManager = ({ match }) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const companies = useSelector(state => state.Companies.allcompanies);

  const toggleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const toggleAddModal = () => {
    setOpenAddModal(val => !val);
  };

  useEffect(() => {
    async function getCompanies() {
      setLoading(true);
      const response = await dispatch(getAllCompanies());
      if (response) setLoading(false);
    }
    getCompanies();
  }, []);

  // localStorage.removeItem("activeID");
  return (
    <Container fluid className="main-content-container px-4">
      <AddIndustry
        open={openAddModal}
        toggle={toggleAddModal}
        handleClose={toggleAddModal}
      />
      {/* Page Header */}
      <Row noGutters className="page-header pt-2">
        <PageTitle
          title="Company Manager"
          subtitle="Registered Companies"
          className="text-sm-left"
        />
      </Row>

      <Row className="d-flex justify-content-end mr-1 my-2">
        <Button
          theme="primary"
          className="mr-1  d-flex align-items-center"
          onClick={toggleOpenAddModal}
        >
          <i
            className="material-icons text-size-2"
            style={{ fontSize: "1.2rem" }}
          >
            add
          </i>
          Add Company
        </Button>
      </Row>

      <Row>
        {!loading ? (
          <IndustryManagerList data={companies} />
        ) : (
          <Spinner variant="primary" />
        )}
      </Row>
    </Container>
  );
};

export default IndustryManager;
