import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  FormRadio
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const LegalForm = ({ state }) => {
  const setReviewPrim = val => {
    console.log("review primary", val);
  };

  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Legal and Adminstrative Framework</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  reviewPrimary: "",
                  companySize: "",
                  landUseArea: "",
                  inadequacies: [{ val: "" }]
                }}
                validationSchema={Yup.object({
                  // reviewPrimary: Yup.string()
                  //   .trim()
                  //   .required("Review Primary is required"),
                  // companySize: Yup.string()
                  //   .trim()
                  //   .required(`Company Size is required in m2`),
                  // landUseArea: Yup.string()
                  //   .trim()
                  //   .required(`Required`),
                  inadequacies: Yup.array()
                    .of(
                      Yup.object().shape({
                        val: Yup.string().min(
                          2,
                          "Must be greater than 2 characters"
                        )
                      })
                    )
                    .required("Must have at least one inadequacy")
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row form>
                      {/*Review of primary and immediate legal admin Framework */}
                      <Col md="6" className="form-group">
                        <label htmlFor="reviewPrimary">
                          Review of primary and immediate legal admin Framework
                        </label>
                      </Col>
                      <Col md="6">
                        <FormRadio
                          id="reviewPrimary"
                          inline
                          name="reviewPrimary"
                          // checked={}
                          onChange={e => {
                            setFieldValue("reviewPrimary", e.target.value);
                            setReviewPrim(e.target.value);
                          }}
                          onBlur={handleBlur("reviewPrimary")}
                          value={values.reviewPrimary}
                        >
                          Yes
                        </FormRadio>

                        <FormRadio
                          id="reviewPrimary"
                          inline
                          name="reviewPrimary"
                          // checked={}
                          onChange={e => {
                            setFieldValue("reviewPrimary", e.target.value);
                            setReviewPrim(e.target);
                          }}
                          onBlur={handleBlur("reviewPrimary")}
                          value={values.reviewPrimary}
                        >
                          No
                        </FormRadio>
                        <small className="text-danger">
                          {" "}
                          {touched.reviewPrimary && errors.reviewPrimary}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row form>
                      {/*Review of Lagos State Laws on the environment as applicable (LSEMP Law, Scrutiny Laws etc) */}
                      <Col md="6" className="form-group">
                        <label htmlFor="reviewPrimary">
                          Review of Lagos State Laws on the environment as
                          applicable (LSEMP Law, Scrutiny Laws etc)
                        </label>
                      </Col>
                      <Col md="6">
                        <FormRadio
                          id="reviewPrimary"
                          inline
                          name="reviewPrimary"
                          // checked={}
                          onChange={e => {
                            setFieldValue("reviewPrimary", e.target.value);
                            setReviewPrim(e.target.value);
                          }}
                          onBlur={handleBlur("reviewPrimary")}
                          value={values.reviewPrimary}
                        >
                          Yes
                        </FormRadio>

                        <FormRadio
                          id="reviewPrimary"
                          inline
                          name="reviewPrimary"
                          // checked={}
                          onChange={e => {
                            setFieldValue("reviewPrimary", e.target.value);
                            setReviewPrim(e.target);
                          }}
                          onBlur={handleBlur("reviewPrimary")}
                          value={values.reviewPrimary}
                        >
                          No
                        </FormRadio>
                        <small className="text-danger">
                          {" "}
                          {touched.reviewPrimary && errors.reviewPrimary}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row form>
                      {/*Review of  */}
                      <Col md="6" className="form-group">
                        <label htmlFor="reviewPrimary">
                          Review of National or Federal Government Law as
                          applicable
                        </label>
                      </Col>
                      <Col md="6">
                        <FormRadio
                          id="reviewPrimary"
                          inline
                          name="reviewPrimary"
                          // checked={}
                          onChange={e => {
                            setFieldValue("reviewPrimary", e.target.value);
                            setReviewPrim(e.target.value);
                          }}
                          onBlur={handleBlur("reviewPrimary")}
                          value={values.reviewPrimary}
                        >
                          Yes
                        </FormRadio>

                        <FormRadio
                          id="reviewPrimary"
                          inline
                          name="reviewPrimary"
                          // checked={}
                          onChange={e => {
                            setFieldValue("reviewPrimary", e.target.value);
                            setReviewPrim(e.target);
                          }}
                          onBlur={handleBlur("reviewPrimary")}
                          value={values.reviewPrimary}
                        >
                          No
                        </FormRadio>
                        <small className="text-danger">
                          {" "}
                          {touched.reviewPrimary && errors.reviewPrimary}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <label htmlFor="reviewPrimary">
                          Summary of Inadequacies in the Legal and Admin
                          Framework
                        </label>
                      </Col>

                      <Col md="5">
                        <FieldArray name="inadequacies">
                          {({ push, remove }) => (
                            <>
                              {values.inadequacies.map((item, index) => {
                                const name = `inadequacies[${index}].val`;
                                const errorMessage = getIn(errors, name);
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormInput
                                        id="inadequacies"
                                        name={name}
                                        placeholder="Include inadequacies in the framework"
                                        value={item.val}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2"
                                      />
                                      {errorMessage && (
                                        <small className="text-danger">
                                          {errorMessage}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className="my-2 mx-2"
                                        size={24}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() => push({ val: "" })}
                                  className="my-2"
                                  size={24}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>

                        {/* <FormInput
                          id="inadequacies"
                          placeholder="Include inadequacies in the framework"
                          value={values.inadequacies}
                          onChange={handleChange("inadequacies")}
                          onBlur={handleBlur("inadequacies")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.inadequacies && errors.inadequacies}{" "}
                        </small> */}

                        {/* <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>Add More</Tooltip>}
                        >
                          <FiPlusCircle className="my-2" size={24} />
                        </OverlayTrigger> */}
                      </Col>
                      {/* <Col md="1">
                        <FiPlusCircle size={24} />
                      </Col> */}
                    </Row>

                    <Button theme="accent" onClick={handleSubmit}>
                      Update Profile
                    </Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
LegalForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

LegalForm.defaultProps = {
  title: "Account Details"
};

export default LegalForm;
