import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const StorageTankForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Storage Tank Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  tankCapacity: [{ type: "", capacity: "" }],
                  gpsLoc: "",
                  fireExtinguishers: [{ type: "", expiryDate: "" }],
                  waterHydrant: "",
                  rawMaterials: [{ material: "", qty: "" }],
                  products: [{ material: "", qty: "" }]
                }}
                validationSchema={Yup.object({
                  tankCapacity: Yup.array().of(
                    Yup.object().shape({
                      type: Yup.string(),
                      capacity: Yup.string()
                    })
                  ),
                  gpsLoc: Yup.string(),
                  fireExtinguishers: Yup.array().of(
                    Yup.object().shape({
                      type: Yup.string(),
                      expiryDate: Yup.string()
                    })
                  ),
                  waterHydrant: Yup.string(),
                  rawMaterials: Yup.array()
                    .of(
                      Yup.object().shape({
                        material: Yup.string().min(
                          2,
                          "Must be greater than 2 characters"
                        ),
                        qty: Yup.string()
                      })
                    )
                    .required("Must have at least one Raw Material"),
                  products: Yup.array()
                    .of(
                      Yup.object().shape({
                        material: Yup.string(),
                        qty: Yup.string()
                      })
                    )
                    .required("Must have at least one Raw Material")
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4">
                        <label htmlFor="tankCapacity">
                          A. &nbsp; Tank Capacity
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="tankCapacity">
                          {({ push, remove }) => (
                            <>
                              {values.tankCapacity.map((item, index) => {
                                const typeName = `tankCapacity[${index}].type`;
                                const capacityName = `tankCapacity[${index}].capacity`;
                                const typeError = getIn(errors, typeName);
                                const capacityError = getIn(
                                  errors,
                                  capacityName
                                );
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormInput
                                        id="tankCapacity"
                                        name={typeName}
                                        placeholder="Tank Type"
                                        value={item.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2"
                                      />
                                      {typeError && (
                                        <small className="text-danger">
                                          {typeError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="tankCapacity"
                                        name={capacityName}
                                        placeholder="Capacity"
                                        value={item.capacity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {capacityError && (
                                        <small className="text-danger">
                                          {capacityError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({ material: "", qty: "" })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4" className="form-group">
                        <label htmlFor="gpsLoc">
                          B. &nbsp; Monitoring Well(s)
                        </label>
                      </Col>
                      <Col md="8">
                        <FormInput
                          id="gpsLoc"
                          placeholder="GPS Location of Monitoring wells"
                          value={values.gpsLoc}
                          onChange={handleChange("gpsLoc")}
                          onBlur={handleBlur("gpsLoc")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.gpsLoc && errors.gpsLoc}{" "}
                        </small>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="fireExtinguishers">
                          C. &nbsp; Emergency Response Action
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="fireExtinguishers">
                          {({ push, remove }) => (
                            <>
                              {values.fireExtinguishers.map((item, index) => {
                                const typeName = `fireExtinguishers[${index}].type`;
                                const expiryDateName = `fireExtinguishers[${index}].expiryDate`;
                                const typeError = getIn(errors, typeName);
                                const expiryDateError = getIn(
                                  errors,
                                  expiryDateName
                                );
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormInput
                                        id="fireExtinguishers"
                                        name={typeName}
                                        placeholder="Number and Type of Fire extinguisher"
                                        value={item.type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2"
                                      />
                                      {typeError && (
                                        <small className="text-danger">
                                          {typeError}
                                        </small>
                                      )}

                                      <FormInput
                                        type="date"
                                        id="fireExtinguishers"
                                        name={expiryDateName}
                                        placeholder="Expiry Date"
                                        value={item.expiryDate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {expiryDateError && (
                                        <small className="text-danger">
                                          {expiryDateError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({ material: "", qty: "" })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="waterHydrant">
                          D. &nbsp; Availability of Water Hydrant
                        </label>
                      </Col>
                      <Col md="8">
                        <label htmlFor="waterHydrant">
                          Availability of Water Hydrant
                        </label>
                        <FormSelect
                          id="waterHydrant"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.waterHydrant}
                        >
                          <option value="">Select Availability</option>
                          <option value="available">Available</option>
                          <option value="nonAvailable">Not Available</option>
                        </FormSelect>
                        <small className="text-danger">
                          {" "}
                          {touched.waterHydrant && errors.waterHydrant}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="text-right mt-3">
                        <Button
                          className="my-3"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
StorageTankForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

StorageTankForm.defaultProps = {
  title: "Account Details"
};

export default StorageTankForm;
