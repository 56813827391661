import { GET_PAYMENTS } from "../types";

const initialState = {
  payments: []
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_PAYMENTS:
      return {
        ...state,
        payments: actions.payload
      };

    default: {
      return state;
    }
  }
}
