import React from "react";
import { Nav } from "shards-react";
import { withRouter } from "react-router-dom";
import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
      showSubCat: false
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);

    //this is to control the nav items that display of the sidebar.
    // if the route contains "details", it should show navitems with submenu set as true
    // else it should show navitem with submenu set as undefined.
    //
    // Route is checked to update the state
    let pathname = this.props.location.pathname;
    if (pathname.includes("details")) {
      this.setState({
        ...this.state,
        showSubCat: true
      });
    } else {
      this.setState({
        ...this.state,
        showSubCat: false
      });
    }
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;
    // console.log("this props", this.props);
    // console.log("this state", this.state);
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items
            .filter(item => {
              if (this.state.showSubCat) {
                return item.submenu == true;
              } else {
                return typeof item.submenu == "undefined";
              }
            })
            .map((item, idx) => (
              <SidebarNavItem
                key={idx}
                item={item}
                match={this.props.match}
                onNavigate={this.props.onNavigate}
              />
            ))}
        </Nav>
      </div>
    );
  }
}

export default withRouter(SidebarNavItems);
