import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import AdminProfile from "./AdminProfile";
import AdminStaff from "./AdminStaff";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminAction } from "../../store/Actions/AdminActions";
import CreateStaffModal from "./CreateStaffModal";
import UpdatePasswordModal from "./UpdatePasswordModal";

function Admin() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);

  const adminProfile = JSON.parse(sessionStorage.getItem("userProfile"));
  const allAdmin = useSelector(state => state.Admin.alladmin);
  const dispatch = useDispatch();

  const toggleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const toggleAddModal = () => {
    setOpenAddModal(val => !val);
  };

  const toggleOpenUpdatePasswordModal = () => {
    setOpenUpdatePasswordModal(true);
  };

  const handleCloseUpdatePassword = () => {
    setOpenUpdatePasswordModal(val => !val);
  };

  useEffect(() => {
    async function getAllAdmin() {
      let response = await dispatch(getAllAdminAction());
      if (response) {
      }
    }

    getAllAdmin();
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <CreateStaffModal
        open={openAddModal}
        toggle={toggleAddModal}
        handleClose={toggleAddModal}
      />
      <UpdatePasswordModal
        open={openUpdatePasswordModal}
        handleClose={handleCloseUpdatePassword}
      />
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Admin"
          subtitle="Admin Module"
          className="text-sm-left mb-1"
        />
      </Row>
      <Row>
        <Col lg={12} className="d-flex justify-content-end mr-1 my-2">
          <Button onClick={toggleOpenAddModal} className="mx-2">
            Create Staff
          </Button>
          <Button onClick={toggleOpenUpdatePasswordModal}>
            Update Password
          </Button>
        </Col>
      </Row>

      <Row>
        <Col lg="4">
          <AdminProfile adminProfile={adminProfile} />
        </Col>

        <Col lg="8">
          <AdminStaff admin={allAdmin} />
        </Col>
      </Row>
    </Container>
  );
}

export default Admin;
