import React from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormTextarea,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";

const TechnicalDetailsForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Other technical details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  desc: ""
                }}
                validationSchema={Yup.object({
                  desc: Yup.string()
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4">
                        <label htmlFor="desc">
                          A &nbsp; List all other technical details and
                          shortcomings
                        </label>
                      </Col>
                      <Col md="8">
                        <FormTextarea
                          placeholder="Please describe other technical details and shortcomings"
                          id="desc"
                          rows="10"
                        />
                      </Col>
                    </Row>
                    <hr />

                    <Row>
                      <Col className="text-right ">
                        <Button theme="accent" onClick={handleSubmit}>
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default TechnicalDetailsForm;
