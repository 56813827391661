import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
  Button
} from "shards-react";
import { MdEmail, MdLocalPhone, MdLocationOn } from "react-icons/md";
import EditCompanyBasicProfile from "../../views/IndustryManager/EditCompanyBasicProfile";
import image from "../../images/content-management/coy.png";

const CompanyProfileCard = ({ companyDetails }) => {
  const [openEditModal, setOpenEditModal] = React.useState(false);

  const toggleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  const toggleEditModal = () => {
    setOpenEditModal(val => !val);
  };

  return (
    <Card small className="my-4 pt-3">
      <EditCompanyBasicProfile
        open={openEditModal}
        toggle={toggleEditModal}
        handleClose={toggleEditModal}
        data={companyDetails}
      />

      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <img
            className="rounded-circle"
            src={image}
            alt={companyDetails && companyDetails.name}
            width="110"
            height="110"
          />
        </div>
        <h4 className="mb-0">{companyDetails && companyDetails.name}</h4>
        <small>RC: {companyDetails.rc} </small>
        <span className="text-muted d-block mb-2">
          {companyDetails && companyDetails.description}
        </span>
        {/* <Button pill outline size="sm" className="mb-2">
        <i className="material-icons mr-1">person_add</i> Follow
      </Button> */}
      </CardHeader>
      <ListGroup flush>
        {/* <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block">Progress</strong>
            <Progress className="progress-sm" value={50}>
              <span className="progress-value">60%</span>
            </Progress>
          </div>
        </ListGroupItem> */}
        <ListGroupItem className="">
          <div style={{ textAlign: "center" }}>
            <MdEmail />: <span className="">{companyDetails.email}</span> <br />{" "}
            <MdLocalPhone />: <span className="">{companyDetails.phone}</span>{" "}
            <br /> <MdLocationOn />:{" "}
            <span className="">{companyDetails.address}</span> <br />
          </div>
          <Row className="mt-2">
            <Col lg="12" className="mb-2  text-center">
              <strong className="d-block">Company ID</strong>
              <span>{companyDetails.companyID || "-"} </span>
            </Col>
            <Col lg="12" className="mb-2 text-center">
              <strong className="d-block">LGA</strong>
              <span>{companyDetails.lg || "-"} </span>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

CompanyProfileCard.propTypes = {
  /**
   * The user details object.
   */
  companyDetails: PropTypes.object
};

CompanyProfileCard.defaultProps = {
  companyDetails: {
    name: "Sierra Brooks",
    avatar: require("./../../images/avatars/0.jpg"),
    jobTitle: "Project Manager",
    performanceReportTitle: "Workload",
    performanceReportValue: 74,
    metaTitle: "Description",
    metaValue:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio eaque, quidem, commodi soluta qui quae minima obcaecati quod dolorum sint alias, possimus illum assumenda eligendi cumque?"
  }
};

export default CompanyProfileCard;
