import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";
import IndustryProfileCard from "../../components/industry-manager/IndustryProfileCard";
import IndustryProfileDetails from "../../components/industry-manager/IndustryProfileDetails";

function IndustryDetails({ location: { state }, ...props }) {
  useEffect(() => {
    try {
      localStorage.setItem("activeID", state.id);
      localStorage.setItem("activeCompany", JSON.stringify(state));
    } catch (error) {
      console.log(error);
    }
  }, [state]);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-2">
        <PageTitle
          title={state && state.companyName}
          subtitle=""
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="4">
          <IndustryProfileCard industryDetails={state} />
        </Col>

        <Col lg="8">
          <IndustryProfileDetails industryDetails={state} />
        </Col>
      </Row>
    </Container>
  );
}

export default withRouter(IndustryDetails);
