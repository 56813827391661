import React from "react";
import { Card, CardBody } from "shards-react";
import MaterialTable from "material-table";

const AdminStaff = ({ admin }) => (
  <Card small className="my-2">
    <CardBody>
      <MaterialTable
        title="Staffs"
        columns={[
          { title: "StaffID", field: "staffID" },
          { title: "Fullname", field: "fullname" },
          { title: "Username", field: "username" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone" },
          { title: "Address", field: "address" },
          { title: "Date", field: "dateCreated" },
          { title: "Status", field: "valid" }
        ]}
        data={admin}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF"
          }
        }}
      />
    </CardBody>
  </Card>
);

export default AdminStaff;
