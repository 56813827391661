import React from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik, FieldArray, getIn } from "formik";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const WasteManagementForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Waste Materials Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  wasteMaterials: [{ material: "", dayQty: "", monthQty: "" }],
                  wasteReduction: ""
                }}
                validationSchema={Yup.object({
                  wasteReduction: Yup.string(),
                  wasteMaterials: Yup.array().of(
                    Yup.object().shape({
                      material: Yup.string(),
                      dayQty: Yup.string(),
                      monthQty: Yup.string()
                    })
                  )
                })}
                onSubmit={values => {
                  console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4">
                        <label htmlFor="wasteMaterials">
                          A. &nbsp; Volume of Waste Masterials
                        </label>
                      </Col>

                      <Col md="8" className="mt-3">
                        <FieldArray name="wasteMaterials">
                          {({ push, remove }) => (
                            <>
                              {values.wasteMaterials.map((item, index) => {
                                const materialName = `wasteMaterials[${index}].material`;
                                const dayQtyName = `wasteMaterials[${index}].dayQty`;
                                const monthQtyName = `wasteMaterials[${index}].monthQty`;

                                const materialError = getIn(
                                  errors,
                                  materialName
                                );
                                const dayQtyError = getIn(errors, dayQtyName);
                                const monthQtyError = getIn(
                                  errors,
                                  monthQtyName
                                );
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between"
                                    }}
                                  >
                                    <>
                                      <FormInput
                                        id="wasteMaterials"
                                        name={materialName}
                                        placeholder="Waste Material"
                                        value={item.material}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />
                                      {materialError && (
                                        <small className="text-danger">
                                          {materialError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="wasteMaterials"
                                        name={dayQtyName}
                                        placeholder="Volume/Day"
                                        value={item.dayQty}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />
                                      {dayQtyError && (
                                        <small className="text-danger">
                                          {dayQtyError}
                                        </small>
                                      )}

                                      <FormInput
                                        id="wasteMaterials"
                                        name={monthQtyName}
                                        placeholder="Volume/Month"
                                        value={item.monthQty}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="mb-2 mx-2"
                                      />

                                      {monthQtyError && (
                                        <small className="text-danger">
                                          {monthQtyError}
                                        </small>
                                      )}
                                    </>

                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip>Remove</Tooltip>}
                                    >
                                      <FiMinusCircle
                                        onClick={() => remove(index)}
                                        className=""
                                        size={"2rem"}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </OverlayTrigger>
                                  </div>
                                );
                              })}

                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Add More</Tooltip>}
                              >
                                <FiPlusCircle
                                  onClick={() =>
                                    push({
                                      material: "",
                                      dayQty: "",
                                      monthQty: ""
                                    })
                                  }
                                  className="my-2"
                                  size={24}
                                  style={{ cursor: "pointer" }}
                                />
                              </OverlayTrigger>
                            </>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4">
                        <label htmlFor="wasteReduction">
                          B. &nbsp; Effort at Promoting waste reduction
                        </label>
                      </Col>
                      <Col md="8">
                        <FormTextarea
                          placeholder="List out all efforts carried out to promote waste reduction"
                          id="wasteReduction"
                          rows="5"
                        />
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col className="text-right">
                        <Button theme="accent" onClick={handleSubmit}>
                          Save
                        </Button>
                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default WasteManagementForm;
