import React, { useEffect } from "react";
import { Container, Row, Card, CardBody, Col } from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import { getAllLogs } from "../../store/Actions/ActivitiesLogActions";
import moment from "moment";

function ActivitiesLog({ match }) {
  const dispatch = useDispatch();
  const allLogs = useSelector(state => state.Logs.allLogs);
  allLogs && allLogs.sort((a, b) => a - b);

  useEffect(() => {
    async function getLogs() {
      await dispatch(getAllLogs());
    }

    getLogs();
  }, []);
  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Activities Log"
          subtitle="Log of all activities on the platform"
          className="text-sm-left mb-2"
        />
      </Row>

      <Row></Row>
      {/* {allLogs.length == 0 ? (
        <Spinner />
      ) : (
        <ActivitiesLogCard allLogs={allLogs} />
      )} */}
      <Row className="mb-3">
        <Col lg="12">
          <Card small className="my-2">
            <CardBody>
              <MaterialTable
                title="Activities Log"
                columns={[
                  { title: "ID", field: "id" },
                  { title: "Category", field: "actionCategory" },
                  { title: "Details", field: "actionDetails" },
                  {
                    title: "Date",
                    field: "actionTime",
                    render: rowData =>
                      rowData.actionTime
                        ? moment
                            .unix(rowData.actionTime)
                            .format("DD/MM/YYYY hh:mm:ss A")
                        : ""
                  }
                ]}
                data={allLogs}
                options={{
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF"
                    // padding: ""
                  },
                  filtering: false
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ActivitiesLog;
