import React, { useState } from "react";
import {
  Container,
  Row,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormInput,
  Col
} from "shards-react";
import { Spinner } from "react-bootstrap";
import logo from "../../images/avatars/lasepa-logo.png";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { otherSignInAction } from "../../store/Actions/AuthActions";

function Signin({ history }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const authresult = useSelector(state => state.Auth.authenticated);

  const handleSignin = async values => {
    setLoading(true);

    const response = await dispatch(otherSignInAction(values));
    if (response === -1) {
      setLoading(false);
    } else if (response.type == 0) {
      setLoading(false);

      window.location.pathname = "/company";
    } else if (response.type == 1) {
      setLoading(false);

      // history.push("/consultant");
      window.location.pathname = "/consultant";
    }
  };
  return (
    <Container
      style={{ height: "100vh" }}
      fluid
      className="d-flex justify-content-center align-items-center"
    >
      <Card>
        <CardHeader style={{ textAlign: "center" }}>
          <img src={logo} alt="logo" style={{ maxWidth: "250px" }} />
        </CardHeader>
        <CardBody>
          <CardTitle className="text-center">Dashboard Access</CardTitle>

          <Formik
            initialValues={{ rcn: "", companyID: "" }}
            validationSchema={yup.object({
              rcn: yup.string().required("RC Number is required"),
              companyID: yup.string().required("Company ID is required")
            })}
            onSubmit={values => {
              // console.log("login credentials ", values);
              handleSignin(values);
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              errors,
              touched
            }) => (
              <>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="rcn">RC Number</label>
                    <FormInput
                      id="rcn"
                      type="text"
                      onChange={handleChange("rcn")}
                      onBlur={handleBlur("rcn")}
                      value={values.rcn}
                      placeholder="RC Number"
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.rcn && errors.rcn}{" "}
                    </small>
                  </Col>
                  <Col md="12">
                    <label htmlFor="companyID">Company ID</label>
                    <FormInput
                      id="companyID"
                      type="text"
                      placeholder="Company ID"
                      onChange={handleChange("companyID")}
                      onBlur={handleBlur("companyID")}
                      value={values.companyID}
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.companyID && errors.companyID}{" "}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-4 text-right">
                    <Button
                      disabled={loading}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          variant="secondary"
                          size="sm"
                        />
                      )}
                      &nbsp; Signin
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Container>
  );
}

export default Signin;
