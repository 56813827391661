import React from "react";

function CompanyProfile() {
  return (
    <div>
      <h3>Company Profile here</h3>
    </div>
  );
}

export default CompanyProfile;
