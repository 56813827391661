import React from "react";
import { Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";

function Consultant() {
  return (
    // <div>yooooo</div>
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header pt-2">
        <PageTitle title="Consultant" subtitle="" className="text-sm-left" />
      </Row>
    </Container>
  );
}

export default Consultant;
