import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import routes from "./routes";
import Signin from "./views/Authentication/Signin";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import Welcome from "./views/Authentication/Welcome";
import ReportPollution from "./views/ReportPollution";

export default () => {
  const token = sessionStorage.getItem("othertoken");

  const [state, setState] = React.useState({ id: 0 });
  const [userType, setUserType] = React.useState("");
  // const loginDetails = JSON.parse(sessionStorage.getItem("otheruserProfile"));
  // if (loginDetails) {
  //   const logintype = parseInt(loginDetails.type);
  //   if (logintype) setUserType(logintype);
  // }

  const updateUrlIndexFromStorage = () => {
    const id = parseInt(localStorage.getItem("activeID"));

    setState({ id });
  };

  //remove all console elements
  // console.log = function() {};

  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <Switch>
        <Route path="/welcome" component={Welcome} />
        <Route path="/report-pollution" component={ReportPollution} />
        <Route path="/signin" component={Signin} />

        {routes(state.id, userType).map((route, index) => {
          if (token) {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={props => {
                  return (
                    <route.layout
                      {...props}
                      onNavigate={updateUrlIndexFromStorage}
                    >
                      <route.component {...props} />
                    </route.layout>
                  );
                }}
              />
            );
          } else {
            return <Redirect to="/welcome" />;
          }
        })}
      </Switch>
    </Router>
  );
};
