import React, { useEffect, useState } from "react";
import {
  Col,
  // Row,
  // Modal,
  // ModalHeader,
  // ModalBody,
  // Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
  // ModalFooter
} from "shards-react";
import { Modal, Spinner, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { createCompanyAction } from "../../store/Actions/CompanyActions";
import bsCustomFileInput from "bs-custom-file-input";
import { uploadProofOfPayment } from "../../store/Actions/PaymentActions";

function ProofOfPayment({ open, invoice, handleClose }) {
  const [loading, setLoading] = React.useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [base64, setBase64Val] = useState("");
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();

  const handleSubmitProof = async values => {
    setLoading(true);

    const reqObj = {
      data: {
        pop: base64,
        fileName: fileName,
        billID: invoice.billID
      }
    };
    const response = await dispatch(uploadProofOfPayment(reqObj));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const reader = new FileReader();
    if (imageFile) {
      setFileName(imageFile.name);

      // to display image
      reader.readAsDataURL(imageFile);
      reader.onloadend = () => {
        setBase64Val(reader.result);
      };
    }
  }, [imageFile]);

  useEffect(() => {
    bsCustomFileInput.init();
  }, []);

  return (
    // <Modal open={open} toggle={toggle} className="modal-overflow">
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5>Proof of Payment</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form>
              <Row>
                <Col lg="12">
                  <label htmlFor="name">Upload Proof</label>
                </Col>
              </Row>

              {/* <Row>
                <Col>
                  <label>Media</label>

                  <div className="custom-file">
                    <input
                      id="imageInput"
                      type="file"
                      className="custom-file-input"
                      onChange={e => {
                        // setFileName(filenames});
                        const files = e.target.files;

                        console.log(files);
                        // handleFile(files);
                      }}
                    />
                    <label className="custom-file-label" htmlFor="imageInput">
                      {fileName.filenames
                        ? fileName.filenames
                        : "Select one or more here..."}
                    </label>
                  </div>
                </Col>
              </Row> */}

              <Row className="mb-2">
                <Col className="custom-file">
                  <input
                    id="imgFile"
                    type="file"
                    className="custom-file-input"
                    name="fileName"
                    onChange={e => {
                      const file = e.target.files[0];
                      if (file) {
                        setFileName(file.name);
                        setImageFile(file);
                      }
                    }}
                  />
                  <label className="custom-file-label" htmlFor="imgFile">
                    {fileName || "Choose file"}
                  </label>
                </Col>
              </Row>

              {/* <Row className="mb-2">
                <Col>
                  <Form.Group as={Row} className="custom-file">
                    <Form.File
                      id="imgFile"
                      type="file"
                      className="custom-file-label"
                      style={{ zIndex: 9 }}
                      multiple
                      label={fileName}
                      onChange={e => {
                        const file = e.target.files[0];
                        setImageFile(file);
                        // console.log("image file", file);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              <Row>
                <Col md="12" className="text-right mt-2">
                  <Button
                    className="my-1"
                    theme="accent"
                    disabled={loading}
                    onClick={handleSubmitProof}
                  >
                    {loading && <Spinner size="sm" variant="secondary" />}
                    Upload
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ProofOfPayment;
