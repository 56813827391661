import React from "react";
import {
  Col,
  Row,
  Form,
  FormInput,
  Button,
  FormTextarea,
  FormSelect
} from "shards-react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { updateCompanyBasicInfoAction } from "../../store/Actions/CompanyActions";

const LGA = [
  "AG",
  "ARA",
  "AL",
  "AM",
  "AP",
  "BD",
  "EP",
  "ET",
  "IB",
  "IF",
  "IK",
  "IKD",
  "KO",
  "LI",
  "LM",
  "MU",
  "OJ",
  "OS",
  "SH",
  "SU"
];

function EditCompanyBasicProfile({ open, toggle, handleClose, data }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleUpdateCompany = async values => {
    setLoading(true);

    const response = await dispatch(updateCompanyBasicInfoAction(values));
    if (response == 1) {
      setLoading(false);
      handleClose();
    } else {
      setLoading(false);
    }
  };

  return (
    // <Modal open={open} toggle={toggle} className="modal-overflow">
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5>Create New Company</h5>{" "}
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Formik
              initialValues={{
                name: `${data && data.name}`,
                rc: `${data && data.rc}`,
                phone: `${data && data.phone}`,
                email: `${data && data.email}`,
                address: `${data && data.address}`,
                lg: `${data && data.lg}`,
                companyID: `${data && data.companyID}`
              }}
              validationSchema={yup.object({
                name: yup.string().required("Company's name is required"),
                lg: yup.string().required("Please select a Local Government"),
                rc: yup.string().required("Company's RC number is required"),
                phone: yup.string().required("Phone number is required"),
                email: yup
                  .string()
                  .email("Enter a valid email")
                  .required("Company's Email is required"),
                address: yup.string().required("Company's Address is required")
              })}
              onSubmit={values => {
                handleUpdateCompany(values);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue
              }) => (
                <Form>
                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="name">Company's Name</label>
                      <FormInput
                        id="name"
                        type="text"
                        placeholder="Please enter Company's name"
                        value={values.name}
                        onChange={handleChange("name")}
                        onBlur={handleBlur("name")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.name && errors.name}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="phone">Phone number</label>
                      <FormInput
                        id="phone"
                        type="number"
                        placeholder="Please enter Company's phone number"
                        value={values.phone}
                        onChange={handleChange("phone")}
                        onBlur={handleBlur("phone")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.phone && errors.phone}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col lg="12">
                      <label htmlFor="email">Email</label>
                      <FormInput
                        id="email"
                        type="email"
                        placeholder="Please enter Company's email"
                        value={values.email}
                        onChange={handleChange("email")}
                        onBlur={handleBlur("email")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.email && errors.email}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col md="6" className="form-group">
                      <label htmlFor="lg">LGA</label>
                      <FormSelect
                        id="lg"
                        value={values.lg}
                        onChange={handleChange("lg")}
                        onBlur={handleBlur("lg")}
                      >
                        <option>Select LGA...</option>
                        {LGA.map((item, index) => (
                          <option key={index} value={item}>
                            {" "}
                            {item}{" "}
                          </option>
                        ))}
                      </FormSelect>
                      <small className="text-danger">
                        {" "}
                        {touched.lg && errors.lg}{" "}
                      </small>
                    </Col>
                    <Col lg="6">
                      <label htmlFor="rc">RC</label>
                      <FormInput
                        id="rc"
                        type="text"
                        placeholder="RC"
                        value={values.rc}
                        onChange={handleChange("rc")}
                        onBlur={handleBlur("rc")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.rc && errors.rc}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <label htmlFor="address">Address</label>
                      <FormTextarea
                        id="address"
                        rows="3"
                        value={values.address}
                        onChange={handleChange("address")}
                        onBlur={handleBlur("address")}
                      />
                      <small className="text-danger">
                        {" "}
                        {touched.address && errors.address}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="my-3" style={{ textAlign: "right" }}>
                      <Button outline theme="info" onClick={handleClose}>
                        Close
                      </Button>
                      <Button className="mx-2 " onClick={handleSubmit}>
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        )}
                        &nbsp; Update Company Profile
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button outline theme="info" type="submit" onClick={handleClose}>
          Close
        </Button>
        <Button type="submit">Add Company</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default EditCompanyBasicProfile;
