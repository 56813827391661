import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  Container
} from "shards-react";
import { Formik, FieldArray, getIn } from "formik";
import { Spinner } from "react-bootstrap";
import * as yup from "yup";
import Navbar from "./NavBar";
import GoogleMapReact from "google-map-react";
import LocationMarker from "./LocationMarker";
import bsCustomFileInput from "bs-custom-file-input";
import { useDispatch } from "react-redux";
import { reportPollutionAction } from "../../store/Actions/ReportActions";
import "react-voice-recorder/dist/index.css";
import { Recorder } from "react-voice-recorder";

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const ReportPollution = ({ center, zoom }) => {
  const [voiceRecord, setVoiceRecord] = useState({
    audioDetails: {
      url: null,
      blob: null,
      chunks: null,
      duration: { h: null, m: null, s: null }
    }
  });
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({ lat: "", lng: "" });
  const [mapaddress, setMapAddress] = useState("");
  const [base64Val, setBase64Val] = useState("");
  const [noiseLevel, setNoiseLevel] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    bsCustomFileInput.init();
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function(result) {
          if (result.state === "granted") {
            console.log("permission granted");
            navigator.geolocation.getCurrentPosition(updateLocation);
          } else if (result.state === "denied") {
            console.log("permission denied");
            alert("Enable Location on your device.");
          } else if (result.state === "prompt") {
            console.log("permission prompt");
            navigator.geolocation.getCurrentPosition(
              updateLocation,
              console.log,
              options
            );
          }
          result.onchange = function() {
            // console.log("result onchange ", result.state);
          };
        });
    } else {
      // console.log("navigation not available");
    }
  }, []);

  const updateLocation = loc => {
    // console.log("coordinates", loc.coords);
    setLocation({ lat: loc.coords.latitude, lng: loc.coords.longitude });
  };

  useEffect(() => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyBcLSMRB3EJnxl5SFrW4OEXhvLoPKl2pbw`
    )
      .then(response => response.json())
      .then(response => {
        // console.log("result ", response);
        if (response.results.length > 0) {
          const mapadd = response.results[0].formatted_address;
          setMapAddress(mapadd);
        }
      });
  }, [location]);

  const handleReportPollution = async values => {
    setLoading(true);
    const reqObj = {
      data: {
        ...values,
        location: `${location.lat},${location.lng}`,
        images: [{ ...values.images[0], base64: `${base64Val}` }]
      }
    };

    const response = await dispatch(reportPollutionAction(reqObj));
    if (response) {
      setLoading(false);
    }
  };

  // voice record methods
  const handleAudioStop = data => {
    setVoiceRecord({ audioDetails: data });
    const randomNoise = randomIntInARange(40, 80);
    setNoiseLevel(randomNoise);
  };

  const randomIntInARange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const handleAudioUpload = file => {
    // console.log("recorded audio", file);
  };

  const handleReset = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: { h: null, m: null, s: null }
    };
    setVoiceRecord({ audioDetails: reset });
  };

  // demo record one
  const handleRecordtwo = () => {
    let audio = new Audio(voiceRecord.audioDetails.url);
    const AudioContext = window.AudioContext || window.webkitAudioContext;

    let audioContext = new AudioContext();
    let analyzer = audioContext.createAnalyser();
    let source = audioContext.createMediaElementSource(audio);
    source.connect(audioContext.destination);
    let dataArray = new Uint8Array(analyzer.frequencyBinCount);
    const a = analyzer.getByteFrequencyData(dataArray);
    // console.log("aaa", a);
  };

  return (
    <div>
      <Navbar />
      <Container
        // style={{ height: "80vh" }}
        //   fluid
        className="d-flex justify-content-center align-items-center p-4 "
      >
        <Card className="my-2" style={{ width: "700px" }}>
          <CardHeader className="border-bottom">
            <h5 className="m-0">Report Environmental Pollution</h5>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Formik
                    initialValues={{
                      fullname: "",
                      email: "",
                      location: `${location.lat}, ${location.lng}`,
                      phone: "",
                      nameofplace: "",
                      typeofcomplaint: "",
                      details: "",
                      noiselevel: "",
                      images: [{ note: "", base64: `${base64Val}` }]
                    }}
                    validationSchema={yup.object({
                      fullname: yup.string().required("Fullname is required"),
                      email: yup
                        .string()
                        .email("Enter a valid email")
                        .required("Email is required"),
                      location: yup.string(),
                      phone: yup.string().required("Phone number is required"),
                      nameofplace: yup
                        .string()
                        .required("Name of place / Address is required"),
                      typeofcomplaint: yup
                        .string()
                        .required("Complaint type is required"),
                      details: yup
                        .string()
                        .required("Name of place / Address is required"),
                      noiselevel: yup.string(),
                      // .required("Name of place / Address is required"),
                      images: yup.array().of(
                        yup.object().shape({
                          note: yup.string(),
                          // .required("Description is required"),
                          base64: yup.string()
                        })
                      )
                    })}
                    onSubmit={values => {
                      // console.log("values", values);
                      handleReportPollution(values);
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      errors,
                      setFieldValue
                    }) => (
                      <Form>
                        <Row>
                          <Col lg="6">
                            <h6>Personal Details</h6>
                            <Row className="mb-2">
                              <Col lg="12">
                                <label htmlFor="fullname">Fullname</label>
                                <FormInput
                                  id="fullname"
                                  type="text"
                                  placeholder="Fullname"
                                  value={values.fullname}
                                  onChange={handleChange("fullname")}
                                  onBlur={handleBlur("fullname")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.fullname && errors.fullname}{" "}
                                </small>
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col lg="12">
                                <label htmlFor="phone">Phone number</label>
                                <FormInput
                                  id="phone"
                                  type="number"
                                  placeholder="Phone number"
                                  value={values.phone}
                                  onChange={handleChange("phone")}
                                  onBlur={handleBlur("phone")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.phone && errors.phone}{" "}
                                </small>
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col lg="12">
                                <label htmlFor="email">Email</label>
                                <FormInput
                                  id="email"
                                  type="email"
                                  placeholder="Email"
                                  value={values.email}
                                  onChange={handleChange("email")}
                                  onBlur={handleBlur("email")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.email && errors.email}{" "}
                                </small>
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col lg="12">
                                <label htmlFor="location">Location</label>
                                <FormInput
                                  id="location"
                                  type="text"
                                  disabled
                                  placeholder="location"
                                  value={mapaddress}
                                  onChange={handleChange("location")}
                                  onBlur={handleBlur("location")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.location && errors.location}{" "}
                                </small>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12">
                                <div style={{ width: "100%", height: "100px" }}>
                                  <GoogleMapReact
                                    bootstrapURLKeys={{
                                      key:
                                        "AIzaSyBcLSMRB3EJnxl5SFrW4OEXhvLoPKl2pbw"
                                    }}
                                    defaultCenter={center}
                                    defaultZoom={zoom}
                                    yesIWantToUseGoogleMapApiInternals
                                  >
                                    <LocationMarker
                                      lat={location.lat}
                                      lng={location.lng}
                                    />
                                  </GoogleMapReact>
                                </div>
                              </Col>
                            </Row>

                            {/* <Row>
                              <Col>
                                <Recorder
                                  record={true}
                                  title={"New recording"}
                                  audioURL={voiceRecord.audioDetails.url}
                                  showUIAudio
                                  handleAudioStop={data =>
                                    handleAudioStop(data)
                                  }
                                  handleOnChange={
                                    value =>
                                      console.log("handle change ", value)
                                    // handleOnChange(value, "firstname")
                                  }
                                  handleAudioUpload={data => {
                                    console.log(
                                      "uploaded reached change ",
                                      data
                                    );
                                    handleAudioUpload(data);
                                  }}
                                  handleRest={() => handleReset()}
                                />
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col lg="12">
                                <label htmlFor="noiselevel">Noise Level</label>
                                <FormInput
                                  id="noiselevel"
                                  type="text"
                                  placeholder="noiselevel"
                                  // value={values.noiselevel}
                                  value={noiseLevel}
                                  disabled
                                  onChange={handleChange("noiselevel")}
                                  onBlur={handleBlur("noiselevel")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.noiselevel && errors.noiselevel}{" "}
                                </small>
                              </Col>
                            </Row> */}
                          </Col>

                          <Col lg="6">
                            <h6>Complaint Info</h6>
                            <Row className="mb-2">
                              <Col md="12" className="form-group">
                                <label htmlFor="typeofcomplaint">
                                  Type of Complaint
                                </label>
                                <FormSelect
                                  id="typeofcomplaint"
                                  value={values.typeofcomplaint}
                                  onChange={handleChange("typeofcomplaint")}
                                  onBlur={handleBlur("typeofcomplaint")}
                                >
                                  <option>Select Complaint Type</option>
                                  <option value="noise">Noise Pollution</option>
                                  <option value="air">Air Pollution</option>
                                  <option value="water">Water Pollution</option>
                                  <option value="land">Land Pollution</option>
                                </FormSelect>
                                <small className="text-danger">
                                  {" "}
                                  {touched.typeofcomplaint &&
                                    errors.typeofcomplaint}{" "}
                                </small>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="12">
                                <label htmlFor="details">Details</label>
                                <FormTextarea
                                  id="details"
                                  rows="3"
                                  value={values.details}
                                  onChange={handleChange("details")}
                                  onBlur={handleBlur("details")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.details && errors.details}{" "}
                                </small>
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col lg="12">
                                <label htmlFor="nameofplace">
                                  Address / Name of place
                                </label>
                                <FormInput
                                  id="nameofplace"
                                  type="text"
                                  placeholder="Name of Place"
                                  value={values.nameofplace}
                                  onChange={handleChange("nameofplace")}
                                  onBlur={handleBlur("nameofplace")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.nameofplace &&
                                    errors.nameofplace}{" "}
                                </small>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <Recorder
                                  record={true}
                                  title={"New recording"}
                                  audioURL={voiceRecord.audioDetails.url}
                                  showUIAudio
                                  handleAudioStop={data =>
                                    handleAudioStop(data)
                                  }
                                  handleOnChange={
                                    value => console.log("handle change ")
                                    // handleOnChange(value, "firstname")
                                  }
                                  handleAudioUpload={data => {
                                    console.log("uploaded reached change ");
                                    handleAudioUpload(data);
                                  }}
                                  handleRest={() => handleReset()}
                                />
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col lg="12">
                                <label htmlFor="noiselevel">Noise Level</label>
                                <FormInput
                                  id="noiselevel"
                                  type="text"
                                  placeholder="noiselevel"
                                  // value={values.noiselevel}
                                  value={noiseLevel}
                                  disabled
                                  onChange={handleChange("noiselevel")}
                                  onBlur={handleBlur("noiselevel")}
                                />
                                <small className="text-danger">
                                  {" "}
                                  {touched.noiselevel && errors.noiselevel}{" "}
                                </small>
                              </Col>
                            </Row>

                            {/* image */}
                            <Row>
                              <Col lg="12" className="mt-3">
                                <label htmlFor="images">Upload Image</label>
                                <FieldArray name="images">
                                  {({ push, remove }) => (
                                    <>
                                      {values.images.map((item, index) => {
                                        const noteName = `images[${index}].note`;
                                        const base64Name = `images[${index}].base64`;
                                        const noteError = getIn(
                                          errors,
                                          noteName
                                        );
                                        const base64Error = getIn(
                                          errors,
                                          base64Name
                                        );
                                        return (
                                          <div key={index}>
                                            <FormTextarea
                                              id="images"
                                              placeholder="Description"
                                              name={noteName}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={item.note}
                                              rows={3}
                                            />

                                            {noteError && (
                                              <small className="text-danger">
                                                {noteError}
                                              </small>
                                            )}

                                            <Col className="custom-file">
                                              <input
                                                id="imgfile"
                                                type="file"
                                                className="custom-file-input"
                                                name={base64Name}
                                                value={item.base64}
                                                onChange={e => {
                                                  const file =
                                                    e.target.files[0];
                                                  const reader = new FileReader();
                                                  reader.readAsDataURL(file);
                                                  reader.onloadend = () => {
                                                    setBase64Val(reader.result);
                                                  };
                                                  //   setFieldValue(
                                                  //     `images[${index}].base64`,
                                                  //     file.name
                                                  //   );
                                                }}
                                                onBlur={handleBlur}
                                              />
                                              <label
                                                className="custom-file-label"
                                                htmlFor="imgfile"
                                              >
                                                Choose file
                                              </label>
                                              {base64Error && (
                                                <small className="text-danger">
                                                  {base64Error}
                                                </small>
                                              )}
                                            </Col>

                                            {/* <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip>Remove</Tooltip>
                                              }
                                            >
                                              <FiMinusCircle
                                                onClick={() => remove(index)}
                                                className=""
                                                size={"2rem"}
                                                style={{ cursor: "pointer" }}
                                              />
                                            </OverlayTrigger> */}
                                          </div>
                                        );
                                      })}

                                      {/* <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Add More</Tooltip>}
                                      >
                                        <FiPlusCircle
                                          onClick={() =>
                                            push({ material: "", qty: "" })
                                          }
                                          className="my-2"
                                          size={24}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </OverlayTrigger> */}
                                    </>
                                  )}
                                </FieldArray>
                              </Col>
                            </Row>

                            {/* end of images */}

                            <Row>
                              <Col
                                className="my-3"
                                style={{ textAlign: "right" }}
                              >
                                <Button
                                  className="mx-2 "
                                  onClick={handleSubmit}
                                >
                                  {loading && (
                                    <Spinner
                                      animation="border"
                                      variant="secondary"
                                      size="sm"
                                    />
                                  )}
                                  &nbsp; Submit Report
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Container>
    </div>
  );
};

ReportPollution.defaultProps = {
  center: {
    lat: 6.465422,
    lng: 3.406448
  },
  zoom: 10
};
export default ReportPollution;
