import React from "react";
import { Container, Row, Card, CardHeader, CardBody, Col } from "shards-react";
import { Link } from "react-router-dom";
import logo from "../../images/avatars/lasepa-logo.png";

const Welcome = () => {
  return (
    <Container
      style={{ height: "100vh" }}
      fluid
      className="d-flex justify-content-center align-items-center"
    >
      <Card
        style={{ textAlign: "center", minWidth: "350px", minHeight: "400px" }}
      >
        <CardHeader>
          <img src={logo} style={{ maxWidth: "250px" }} />
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Link
                to={{
                  pathname: "/report-pollution"
                }}
                className="btn"
                style={{
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  color: "black",
                  border: "1px solid #046E34",
                  width: "200px",
                  borderRadius: 25,
                  background:
                    "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(4,110,52,1) 0%, rgba(255,255,255,1) 100%)"
                }}
              >
                Make a Complaint
              </Link>
            </Col>
          </Row>

          <Row>
            <Col>
              <Link
                to={{
                  pathname: "/signin"
                }}
                className="btn"
                style={{
                  marginTop: 25,
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  color: "black",
                  border: "1px solid #046E34",
                  width: "200px",
                  borderRadius: 25,
                  background:
                    "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(4,110,52,1) 0%, rgba(255,255,255,1) 100%)"
                }}
              >
                Sign in
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default Welcome;
