import { GET_ALL_COMPANIES, GET_COMPANY } from "../types";

const initialState = {
  allcompanies: [],
  company: null
};

export default function(state = initialState, actions) {
  switch (actions.type) {
    case GET_ALL_COMPANIES:
      return {
        ...state,
        allcompanies: actions.payload
      };
    case GET_COMPANY:
      return {
        ...state,
        company: actions.payload
      };
    default: {
      return state;
    }
  }
}
