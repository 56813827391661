import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../../../components/common/PageTitle";
import Documents from "./Documents";
import PreviewDocs from "./PreviewDocs";

function Attachement() {
  const userProfile = JSON.parse(localStorage.getItem("activeCompany"));

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title=""
          subtitle="Files and Documents Upload"
          className="text-sm-left mb-3"
        />
      </Row>

      <Row>
        <Col lg="12" className="mb-4">
          <Tabs defaultActiveKey="docForm">
            <Tab
              eventKey="docForm"
              title="Documents Manager"
              tabClassName="font-weight-bold p-3"
            >
              <Documents />
            </Tab>
            <Tab
              eventKey="docSummary"
              title="Preview Documents"
              tabClassName="font-weight-bold p-3"
            >
              <PreviewDocs data={userProfile} />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
}

export default Attachement;
