import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button
} from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";

const BakeHouseForm = ({ state }) => {
  return (
    <Card small className="">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Bake-House / Warehouse Details</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Formik
                initialValues={{
                  ambTemp: "",
                  houseTemp: "",
                  houseType: ""
                }}
                validationSchema={Yup.object({
                  ambTemp: Yup.string(),
                  houseTemp: Yup.string(),
                  houseType: Yup.string()
                })}
                onSubmit={values => {
                  // console.log("form submit", values);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                  setFieldValue
                }) => (
                  <Form>
                    <Row>
                      <Col md="4" className="form-group">
                        <label htmlFor="ambTemp">Ambient Temperature</label>
                      </Col>
                      <Col md="8">
                        <FormInput
                          id="ambTemp"
                          placeholder="Ambient Temperature"
                          value={values.ambTemp}
                          onChange={handleChange("ambTemp")}
                          onBlur={handleBlur("ambTemp")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.ambTemp && errors.ambTemp}{" "}
                        </small>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md="4">
                        <label htmlFor="houseTemp">
                          BakeHouse / Oven Temperature
                        </label>
                      </Col>

                      <Col md="8">
                        <FormInput
                          id="houseTemp"
                          placeholder="Bakehouse / Oven Temperature"
                          value={values.houseTemp}
                          onChange={handleChange("houseTemp")}
                          onBlur={handleBlur("houseTemp")}
                        />
                        <small className="text-danger">
                          {" "}
                          {touched.houseTemp && errors.houseTemp}{" "}
                        </small>
                      </Col>
                    </Row>

                    <hr />

                    <Row>
                      <Col md="4" className="form-group">
                        <label htmlFor="houseType">
                          Type of Oven / Bakehouse
                        </label>
                      </Col>
                      <Col md="8">
                        <FormSelect
                          id="houseType"
                          onChange={handleChange("houseType")}
                          onBlur={handleBlur("houseType")}
                          value={values.houseType}
                        >
                          <option value="">Select Oven Type</option>
                          <option value="catA">
                            Category A (modern/no emission)
                          </option>
                          <option value="catB">
                            Category B (local/emission)
                          </option>
                          <option value="catC">Category C (obsolette)</option>
                        </FormSelect>
                        <small className="text-danger">
                          {" "}
                          {touched.houseType && errors.houseType}{" "}
                        </small>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="text-right">
                        <Button
                          className="my-3"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>

                        <Button
                          outline
                          className="my-3 mx-2"
                          theme="accent"
                          onClick={handleSubmit}
                        >
                          Preview Details
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
BakeHouseForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

BakeHouseForm.defaultProps = {
  title: "Account Details"
};

export default BakeHouseForm;
