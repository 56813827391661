import React from "react";
import { Row, Col, Form, FormInput, FormTextarea, Button } from "shards-react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { updateCompanyConsultant } from "../../store/Actions/CompanyActions";

function ConsultantInfoTab({ data }) {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleConsultantUpdate = async values => {
    setLoading(true);
    const obj = {
      data: {
        ...values
      }
    };
    const response = await dispatch(updateCompanyConsultant(obj));
    if (response) {
      setLoading(false);
    }
  };
  return (
    <div className="p-4">
      <Row>
        <Col>
          <Formik
            initialValues={{
              id: `${data && data.id}`,
              name: "",
              phone: "",
              address: "",
              email: "",
              companyID: `${data && data.companyID}`
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .trim()
                .required("Required"),
              email: Yup.string()
                .email("Invalid email")
                .required("Required"),
              phone: Yup.string()
                .trim()
                .required("Required"),
              address: Yup.string()
                .trim()
                .required("Required")
            })}
            onSubmit={values => {
              handleConsultantUpdate(values);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors
            }) => (
              <Form>
                {/* <Row form>
                 */}

                {/* Medical Retainership */}
                <Row>
                  <Col>
                    <h6>Company's Consultant Details</h6>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col md="12">
                    <FormInput
                      id="name"
                      type="text"
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange("name")}
                      onBlur={handleBlur("name")}
                      className="my-2"
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.name && errors.name}{" "}
                    </small>
                  </Col>

                  <Col md="12">
                    <FormInput
                      id="email"
                      type="email"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      className="my-2"
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.email && errors.email}{" "}
                    </small>
                  </Col>

                  <Col md="12">
                    <FormInput
                      id="phone"
                      type="number"
                      placeholder="Phone"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      onBlur={handleBlur("phone")}
                      className="my-2"
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.phone && errors.phone}{" "}
                    </small>
                  </Col>

                  <Col md="12">
                    <FormTextarea
                      id="address"
                      type="text"
                      placeholder="Address"
                      value={values.address}
                      onChange={handleChange("address")}
                      onBlur={handleBlur("address")}
                      className="my-2"
                      rows="3"
                    />
                    <small className="text-danger">
                      {" "}
                      {touched.address && errors.address}{" "}
                    </small>
                  </Col>
                </Row>

                <Button theme="accent" onClick={handleSubmit}>
                  {loading && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                  &nbsp; Update Profile
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default ConsultantInfoTab;
